.main-frame {
    height: 100%;

    @media screen and (max-width: 550px) {
        margin-top: -1.4rem;
        height: calc(100vh - 4.785rem);
    }

    .baner {
        background-color: var(--cu-black-500);
    }

    .frame {
        width: 100%;
        background: var(--cu-black-700);

        @media screen and (min-width: 551px) {
            height: calc(100%);
        }

        @media screen and (max-width: 550px) {
            min-height: calc(100vh - 3rem);
        }
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.625rem;
        width: 100%;
        height: 100%;
        background: var(--cu-black-700);
        /* identical to box height, or 121% */


        /* Shades/White */

        color: var(--cu-white-900);

        .card {
            position: relative;
            display: inline-block;
            cursor: pointer;

            .user-avatar,
            .img-top {
                border: 0.0625rem solid var(--cu-white-900);
                border-radius: 50%;
                padding: .7813rem;
                width: 3.75rem;
                height: 3.75rem;
            }

            .profile-pic {
                padding: 0;
                overflow: hidden;

            }

            .img-top {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 99;
                background-color: var(--cu-black-500);
            }

            &:hover .img-top {
                display: inline;
            }


        }



        .name-bar {
            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.625rem;
            gap: 0.625rem;

            width: 100%;
            flex: 0 0 5rem;

            /* Dark Mode / 900 */

            background: var(--cu-black-500);
            /* Dark Mode / 300 */

            border: 0.0625rem solid var(--cu-black-100);
            border-radius: .625rem;

            /* Inside auto layout */

            font-size: 0.87rem;

            .profile-nav-toggle {
                display: none;
            }
        }



        .sub-content {
            flex: 1 1 calc(100% - 5.625rem);

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                gap: 0.625rem;
                flex: 1 1 calc(100% - 16.625rem);

                /* Neutral / 900 */

                background: var(--cu-black-700);
                border-radius: .625rem;

                overflow: auto;
-webkit-overflow-scrolling: touch;

                @media screen and (max-width: 550px) {
                    overflow: unset;
                    gap:1rem;
                }


            }

            .nav-bar {
                padding: 0.625rem 0;
                flex: 0 0 16rem;
                overflow: auto;
-webkit-overflow-scrolling: touch;
                /* Dark Mode / 900 */

                background: var(--cu-black-500);
                /* Dark Mode / 300 */

                border: 0.0625rem solid var(--cu-black-100);


                ul {
                    width: 100%;

                    li {
                        text-align: left;
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        cursor: pointer;
                        margin-bottom: .625rem;

                        font-size: .875rem;



                    }

                    li:first-child {
                        padding-top: 0.625rem;
                    }
                }
            }
        }

        @media screen and (max-width: 1030px) {
            .sub-content .nav-bar {
                flex: 0 0 11.562rem;
            }
        }

        @media screen and (max-width: 767px) {
            .sub-content .nav-bar {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 0;
                width: 100%;
                transform: translateY(100);
                z-index: -1;
                transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
            }

            .sub-content .nav-bar.nav-bar-active {
                transform: translateY(0%);
                visibility: visible;
                opacity: 1;
                z-index: 1;
                transition-delay: 0s, 0s, 0.3s;
                transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

            }

            .name-bar .profile-nav-toggle {
                display: block;
            }
        }

    }

    @media screen and (max-width: 550px) {
        .main-frame {
            position: relative;
            margin-top: -11px;
        }

        .sub-content {
            position: inherit;
        }


        .profile {
            padding: 0;
        }

        .baner {
            overflow: auto;
-webkit-overflow-scrolling: touch;
            white-space: nowrap;
            position: fixed;
            width: 100%;
            z-index: 4;
            top: 50px;
        }

        .page-title {
            font-size: .875rem;
            padding-left: .625rem;
            line-height: 1rem;
        }
    }

}

.mobile-head {
    font-size: 0.75rem;
    font-weight: 0 !important;
    color: var(--cu-black-10);
    padding-left: 0.5rem;
    .linkActive {
        color: var(--cu-white-550);
    }
    .nav-link {
        color: var(--cu-white-450);
    }
}




.baner::-webkit-scrollbar {
    display: none;
}