.gallery-frame-wall {
    justify-content: center;
}


.wall {
    height: 100%;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
    }
}

.gallery-frame-wall {
    display: grid;
    column-gap: 2rem;
    align-items: center;
    aspect-ratio: 16/9;
    grid-template-columns: 21% 25% 17%;
    padding-bottom: 3rem;

    &.multi-view {
        .g-row:nth-child(3) {
            .gallery-nft-frame-viewer .frame:first-child {
                max-height: 100vh !important;
            }
    
            .gallery-images {
                img:first-child {
                    max-height: 100vh !important;
                }
            }
        }
    }
    @media screen and (max-width: 550px) {
        padding-bottom: 2rem;
    }

    .g-row:nth-child(2) {
        gap: 7px;
    }

    .g-row:nth-child(3) {
        gap: 7px;

        .gallery-nft-frame-viewer .frame:first-child {
            max-height: 5rem !important;
        }

        .gallery-images {
            img:first-child {
                max-height: 5rem !important;
            }
        }
    }
}


.g-5div:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.3rem;
}

.gallery-frame-wall {
    .g-row div:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;

        .gallery-nft-frame-viewer .frame:first-child {
            max-height: 6rem;
        }

        .gallery-images {
            img:first-child {
                max-height: 6rem;
            }
        }

        // img:first-child {
        //     max-height: 6rem;
        // }

        // .g-column:nth-child(1) {
        // background-color: black;
        //}
    }


    .g-row div:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .g-row div:nth-child(3) {
        grid-column-start: 3;
        grid-column-end: 5;


    }

    .g-row:nth-child(2) div:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;

    }

    .g-row:nth-child(2) div:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
    }

    .g-row:nth-child(2) div:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 5;

        .gallery-nft-frame-viewer .frame:first-child {
            max-height: 6rem;
        }

        .gallery-images {
            img:first-child {
                max-height: 6rem;
            }
        }
    }

    .g-row:nth-child(3) div:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
    }

    .g-row:nth-child(3) div:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}

.frame-image {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    background-position: top center;
    height: 100%;
    width: 100%;
}

.preview-grid-show {
    .gallery-images-position .gallery-images, .card-layout-container .react-loading-skeleton{
        height: 100% !important;
    }    
}


.preview-gallery-new {
    .nft-frame-background {
        background: transparent !important;
    }
}

.screenshot-popup {
    .popup-template {
        @apply inset-0;
    }

    .gallery-slider {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    }
}

.preview-card-image {
    max-height: calc(100vh - 5.4rem);
    display: grid;
    grid-template-columns: calc(100% - 30rem) 29rem;
    gap: 1rem;
    padding: 1rem;

    @media screen and (max-width: 550px) {
        grid-template-columns: 100%;
    }
}


.gallerylayout {    
    height: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    .gallery-images {
        img {
            max-height: 9.4rem;

            @media screen and (max-width: 550px) {
                max-height: 6.4rem;
            }
        }

    }
}

.loadinglogo {
    width: 100%;
    height:100%;
    background: black;
    opacity: 0;
    transition: opacity 1.5s ease-out;  
    position: absolute;
    top: 0;
    z-index:10;
    pointer-events: none;
  }
  
  .loadinglogo.visible {
    opacity: 1;
  }
  
  .loadinglogo img {
    width: 80%;
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.progress-loader {
    display: none;
    &.show {
        display: flex;
    }
    position: fixed;
    width: 100vw;
    height: 100%;
    background: var(--cu-black-900);
    justify-content: center;
    align-items: center;
    z-index: 11 ;
    .loader {
        display: flex;
        width: 50px;
        aspect-ratio: 1;
        &:before,
        &:after {
          content: "";
          flex: 1;
          margin: 0 0 0 -2px;
          --c:no-repeat linear-gradient(#fff 0 0);
          background: 
            var(--c) 0    0, 
            var(--c) 100% 0, 
            var(--c) 100% 100%, 
            var(--c) 0    100%, 
            var(--c) 0    50%;
          animation: l17 1s infinite alternate;
        }
        &:before {
            transform: scale(-1);
            margin:0 -2px 0 0;
        }    
        @keyframes l17 {
            0%,
            5%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   ,0    4px}
            20%  {background-size: 100% 4px,4px 0   ,0    4px,4px 0   ,0    4px}
            40%  {background-size: 100% 4px,4px 100%,0    4px,4px 0   ,0    4px}
            60%  {background-size: 100% 4px,4px 100%,100% 4px,4px 0   ,0    4px}
            80%  {background-size: 100% 4px,4px 100%,100% 4px,4px 50% ,0    4px}
            95%,
            100% {background-size: 100% 4px,4px 100%,100% 4px,4px 50% ,100% 4px}
        }
    
    }
}