.accordion {
    background: #0D0F11;
    padding: 0.325rem;
    padding-left: 0.7rem;
    .accordion-arrow {
        &-expanded {
            margin-bottom: 0.5rem;
        }
    }
}
.fs-nft {
    .expander-content {
        padding-left: 0 !important;
    }
    .search-input-container {
        width: 100%;
        line-height: 1.0625rem;
        .search-icon-search {
            position: absolute;
            top: 0.4rem;
            left: 0.5rem;
            display: unset;
        }
        input {
            border: 1px solid var(--neutral-500);
            border-radius: 0.5rem;
            background-color: transparent;
            font-size: 0.625rem;
            font-weight: 600;
            padding: 0.3rem 1rem;
            width: 100%;
        }
        .search-icon-close {
            position: absolute;
            top: 0.4rem;
            right: 0.5rem;
            display: unset;
        }
    }

    .searchtext {
        color: var(--cu-white-700);
        font-size: 0.625rem;
        font-weight: 600;
        padding-bottom: 1em;
    }
}
.max-height-ios{
    height: -webkit-fill-available;
}

.frame-filter-wrapper{
    .expander-content,.acco-child{
        padding-left: 0rem;
    }
}