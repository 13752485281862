.tab-container {
  padding: 1.14286rem 2.28571rem 0rem 1.14286rem;
  background: var(--cu-black-500);
  .tab-data {
    cursor: pointer;
    color: var(--cu-black-10);
    font-size: .875rem;
    line-height: 1.21429rem;
    padding-bottom: 0.5rem;
    &.active {
      color: var(--cu-blue-70);
      border-color: var(--cu-blue-70);
      border-bottom-width: 2px;
    }
  }
}
.details-container {
  .preview-card-image {
    padding: 0;
    @media screen and (max-width: 550px) {
      overflow: auto;
-webkit-overflow-scrolling: touch;
      padding: 0rem 0rem 12rem 0rem;
    }
  }
  @media screen and (max-width: 550px) {
    .slider-user {
      min-height: 100vh;
      height: auto;
      .description-tab {
        max-height: 100%;
      }
    }
  }
}
.right-panel-b-border {
  border-bottom-width: 1px;
  border-color: var(--cu-black-100);
  width: 100%;
  &.w-auto {
    width: auto;
  }
}
.discover-creator-details {
  .details-container {
    @media screen and (max-width: 550px) {
      .slider-user {
        height: calc(100vh - 0rem);
      }
    }
  }
  @media screen and (max-width: 550px) {
    .mobileTab {
      .tab-list {
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        .tab-data {
          display: flex;
          gap: 0.4rem;
          font-size: 0.75rem;
        }
      }
    }
    .discover-collection-container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .collection-name {
        font-size: 0.5rem;
      }
      .count-holdr,
      .community-pic {
        display: none;
      }
    }
    .head-title .title {
      font-size: 0.75rem;
    }
    .list-right-panel {
      border: none;
      border-radius: 0;
    }
    .menubar {
      display: none;
    }
    .discover-announcement-container {
      .lg-accord-card {
        display: none;
      }
    }
  }
}

.desc-title{
  font-size: 0.875rem;
  text-align: left;
  color: var(--cu-white-100);
}