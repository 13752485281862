.create-edit-collection {
  margin: 1rem 0;
  .tab-container{
    padding: 0.75rem 0rem 0rem 0rem;
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 600;
  }
.cursor-not-allowed{
  cursor: no-drop !important
}
  .section-title {
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 550px) {
      font-size: 0.75rem;
    }
  }

  .brd-btm {
    padding-bottom: 0.625rem;
    @media screen and (max-width: 550px) {
      border-bottom:none;
      padding-bottom: 0rem;
    }
  }

  label {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }

  :is(input, textarea)::placeholder {
    color: var(--cu-black-10);
  }

  .formControl,
  .autoCom ul>li {
    font-size: 0.875rem;
    color: var(--neutral-500);
  }
  .formControl {
    padding: .625rem;
  }
  .select-box--container{
    border-radius: 50px !important;
  }

  input,
  textarea {
    background-color: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    color: var(--cu-white-200);
    font-size: 0.875rem;
  }

  textarea {
    max-height: 7rem;
    min-height: 4rem;
    border-radius: 12px !important;
    padding: 0.5rem 0.8rem !important;
  }
  input {
    border-radius: 50px !important;
    padding: 0.6rem 0.8rem !important;
  }

  .upload-banner-image {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 12px;
    height: 10.25rem;

    label {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .upload-icon {
      display: none;
      background-color: var(--cu-black-700);
      margin: auto;
    }

    .dummy-wrap {
      .image-uploaded{
        @media screen and (max-width: 550px) {
          height: 2.75rem;
          width: 3.75rem;
        }
      }
      p {
        text-align: center;
        font-size: 0.625rem;
        color: var(--cu-black-10);
      }
    }

    .image-uploaded {
      padding: 0px;
      margin: auto;
      overflow: hidden;
      object-fit: contain;
      max-height: 4.25rem;
      border-radius: 12px;
      height: 100%;
      &.actual-img {
        width: 100%;
        object-fit: initial;
      }
    }

    &.profile-img {
      width: 10.375rem;
      height: 10.25rem;
@media screen and (max-width: 550px) {
  height: 9.875em;
  width: 100%;
}
      .image-uploaded.actual-img {
        max-height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    &:hover {
      .upload-icon {
        display: inline;
      }

      .dummy-wrap,
      .image-uploaded {
        display: none;
      }
    }
  }

  .new-checkbox span {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    color: var(--cu-black-10);
    padding: 0.3rem 1rem;
    font-weight: 500;
    font-size: .875rem;
    cursor: pointer;
    &.disabled{
      cursor: no-drop;
    }
    @media screen and (max-width: 550px) {
      padding: 0.5rem 1rem;
      font-weight: 400;
      font-size: 0.75rem;
    }
    &.active {
      border-color: var(--cu-white-900);
    }
  }

  .button {
    padding: 0.5rem 0.875em;
    background: unset;

    &.save {
      background: var(--cu-black-700);
      color: var(--cu-black-10);
      border-color: var(--cu-grey-650);
    }

    &.active {
      color: var(--cu-white-900);
      // border-color: var(--cu-blue-60);
    }
  }

  &.create {
    h3 {
      font-size: 1.375rem;
      font-weight: 600;
    }

    textarea {
      max-height: 33.5rem;
      resize: none;

      @media screen and (max-width: 550px) {
        max-height: 7.5rem;
      }
    }

    .new-checkbox span {
      color: var(--cu-white-900);
    }

    .sub-title {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 550px) {
    &.create {
      .button {
        width: 100%;
      }
    }
  }

  .foot-text {
    font-size: 1rem;
    color: var(--cu-black-5);
    line-height: 1.21429rem;
  }

  .panel-accord {
    display: flex;
    align-items: center;
    gap: 0.71429rem;
    border-radius: 0.28571rem;
    background: var(--neutral-200);
    justify-content: space-between;
    @media screen and (max-width: 550px) {
      padding: 0rem;
    }
    .accord-title {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.02286rem;
      @media screen and (max-width: 550px) {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    .accord-arrow {
      img {
        transform: rotate(180deg);

        &.active {
          transform: rotate(0deg);
        }
      }
    }
  }

  .community-list-container {
.card-details {
  .detail-profile {
      border-radius: 2.14286rem;
      border: 1px solid var(--cu-black-100);
      width: 1.25rem;
      height: 1.25rem;
      object-fit: cover;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
    }
  }
  .image-text {
    font-size: 0.625rem;
    font-weight: 500;
}
}
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 0.71429rem;
      align-items: center;      
      padding-top: 12px;
      @media screen and (max-width: 550px) {
        align-items: start;      
        padding: 0px;
        gap: 0.375rem;
      }
      .image-container {
        @media screen and (max-width: 550px) {
          padding: 2px;
          border: 2px solid transparent; 
          border-radius:2px;  
        }
      }
      &.active {
        padding: 10px;
        border: 2px solid;  
        border-radius: 4px;    
        @media screen and (max-width: 550px) {
          padding: 0px;
          border: none;  
          border-radius: 0px;  
        }
        .image-container {
          position: relative;
          @media screen and (max-width: 550px) {
            padding: 2px;
            border: 2px solid; 
            border-radius:2px;  
          }
        }
      }

      .image-container {
        border-radius: 6.57143rem;
        object-fit: cover;
        @media screen and (max-width: 550px) {
          border-radius:2px;
          overflow: hidden;
          width: 100%;
        }
        img {
          width: 7.57143rem;
          height: 7.57143rem;
          border-radius: 6.57143rem;
          @media screen and (max-width: 550px) {
            width: 100%;
            height: 6.25rem;
            border-radius:2px;
          }
        }
      }

      .title-container {
        font-weight: 500;
        font-size: 1rem;
        max-width: 7.5rem;
        line-height: 0.85714rem;
      }
    }
    &.body{
      @media screen and (max-width: 550px) {
        gap: 0.5rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(44%, 1fr));
      }
    }
  }
}
.community-collection-main-form {
  .name-id{
    .collection-name{
      font-size: 1rem;
      font-weight: 700;
      color: var(--cu-white-200);
    }
  }
  textarea{
    height: 18.75rem;
    resize: none;
    max-height: 100%;
  }
  .button {
    background-color: var(--neutral-900);
    padding: 0.42857rem 0.71429rem;
    border-radius: 0.25rem;
    border: 1px solid var(--dark-mode-300);
    font-size: 0.875rem;
    font-weight: 400;
    display: flex;
    width: fit-content;
    height: fit-content;
    .btn-dark{
      line-height: normal;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
      font-size: 0.75rem;
      font-weight: 400;
      padding: 0.5rem;
      border-radius: 0.75rem;
    }
    &.save {
      background-color: var(--neutral-900);
      padding: 0.42857rem 0.71429rem;
      border-radius: 0.25rem;
      border: 1px solid var(--dark-mode-300);
      font-size: 0.75rem;
      font-weight: 400;
      display: flex;
      width: fit-content;
      height: fit-content;
  
    }
    &.submit{
      padding: 0.42857rem 2rem;
      border-radius: 0.6875rem;
      color: var(--cu-black-10);
    }
    &.edit-active{
      color: var(--cu-white-900);
      border: 0.0625rem solid var(--cu-white-900);
    }
    &.active {
      color: var(--cu-white-900);
      border: 0.0625rem solid var(--cu-white-900);
    }
    &.remove{
      color: var(--cu-red-500);
      border-color: var(--cu-red-500);
    }
  }

  .savetag {
    width:14rem;
    margin-left:1rem;
    border: 0.0625rem solid var(--cu-white-900);
    padding: 0.6875rem 0.625rem;
    &.disable-tag-btn{
      border: 0.0625rem solid var(--dark-mode-300);
      color: var(--cu-black-10);
      cursor: not-allowed;
    }
  }
  .asset-loading {
    .skelton-card {
      padding: 0;
    }
  }
  .disabled_className {
    .select-box--container {
      background: var(--dark-mode-900);
      cursor: not-allowed;
      .select-box--arrow{
        img {
          display: none;
        }
      }
    }
    input{
      background-color: var(--dark-mode-900);
      cursor: not-allowed;
      color: var(--cu-black-10);
    }
  }
  .collection-update_btn-container {
    width: 100%;
    .save-btn, .cancel-btn {
      width: 40%;
      font-size: 0.75rem;
      padding: 0.65rem 1rem;
      border-radius: 5rem;
      transition: all 0.3s;
      color: var(--cu-white-900);
      border: none;
      box-shadow: 0 0 0 1px var(--cu-black-100);
      &:hover {
        background: var(--dark-mode-300);
      }
    }
    .save-btn {
      background: var(--dark-mode-900);
    }
    .edit-active {
      box-shadow: 0 0 0 1px var(--cu-white-100)
    }
  }
  .collection-image-wrapper{
    .reload-icon{
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 2px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("/Assets/Icons/reload.svg");
      &:hover {
        background-image: url("/Assets/Icons/reload-hover.svg");
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg); 
      }
      to {
        transform: rotate(360deg); 
      }
    }
    .reload-active {
      animation: rotate 2s linear infinite;
    }
    .upload-banner-image {
      height: 100%;
      position: relative;
      &.profile-img {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 1;
        transition: all 0.5s ease-in-out;
        @media screen and (max-width: 550px) {
          height: 100%;
          width: 100%;
        }
        .dummy-wrap {
          display: inline-block;
          transition: 0.4s 0.2s ease-in-out;
          opacity: 1;
          transform: scale(1);
        }
        .upload-icon {
          position: absolute;
          inset: 0;
          transform: scale(0.4);
          opacity: 0;
          transition: 0.4s 0.2s ease-in-out;
          display: inline-block;
        }
        .select-image-button{
          width: 0;
          padding: 0.425rem 0.625rem;
          border-radius: 0.5rem;
          border: 1px solid var(--cu-black-100);
          background: var(--cu-black-700);
          font-size: 0.85rem;
          color:var(--cu-white-200);
          aspect-ratio: 1 / 0.03;
          z-index: 1;
        }
        &:hover {
          .upload-icon {
            transform: scale(1);
            opacity: 1; 
            width: 8.5rem;
          }
    
          .dummy-wrap {
            opacity: 0;
            transform: scale(0);
          }
          .image-uploaded{
            display: inline-block;
            opacity: 0.6;
          }
        }
      }
    }
   
    .collage-image{
      .upload-banner-image {
      &:hover {
        .upload-icon {
          transform: scale(1);
          opacity: 1; 
          width: 8.5rem;
        }
  
        .dummy-wrap {
          display: inline-block;
          opacity: 1;
          transform: scale(1);
        }
        .image-uploaded{
          display: inline-block;
          opacity: 1;
        }
      }
    }
    }
  }
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: 10px;
}

.image-grid img {
  max-width: 100%;
  height: auto;
  display: block;
}
.warning-msg{
  label{
  font-size: 0.875rem;
  }
}