.music-player.toplevel {
    // position: absolute;
    // bottom: 2.5rem;
    // right: 1.25rem;
    z-index: 1001;
    // -webkit-backdrop-filter: blur(3px);
    // backdrop-filter: blur(3px);
    max-width: 21.75rem;
    .list-player {    
        background: var(--cu-black-500);
        border: 1px solid var(--cu-black-100);
        min-width: 21.75rem;
        .lt-info-panel {
            opacity: 1;
        }

        a:hover {
            text-decoration: none;
        }
        
        .list-header {
            height: 11rem !important;
            .lh-children-cont {
                background: var(--dark-mode-900);
                padding: 1rem 1.5rem 0rem 1.5rem;
                .lt-info-card{
                    .lt-info-img-cont{
                        width: 3.75rem;
                    }
                }
            }
            h1.lt-info-title-h1>span {
                font-size: 1rem;
                color: var(--cu-white-900);
                -webkit-line-clamp: 2;
                font-weight: 500;
                overflow: hidden;    
                display: -webkit-box;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
            
            .lt-info-stats {
                display: none;
            }

            .controller-panel {
                background-color: transparent;
                left: 0;
                width: 100%;
                button.back-btn{
                    order: 1;
                }
                button.mute-btn{
                    position: absolute;
                    right: 1.375rem;
                }
                button:is(.play-btn,.forward-btn,.back-btn,.mute-btn){
                    .action-icon{
                        width: 1.75rem;
                        height: 1.75rem;
                    }
                }
                
            }

            .lt-info-art-placeholder-cont {
                height: 5rem;
                width: 5rem;
            }
        }

        .track {
            cursor: pointer;
            background: var(--cu-black-500);
            .meta-cont {
                display: none;
            }

            span.number {
                font-size: initial;
            }
        }        
    }

    .toggle-music-player {
        display: flex;
        justify-content: end;
        margin-top:0.5rem;
        &.active{
          button{
            background: var(--cu-black-50);   
          }  
        }
        button {
            background: var(--dark-mode-900);
            border-radius: 2rem;
            padding: 1rem;
            z-index: -1;
            border: 1px solid var(--cu-black-100);
            &:hover{
                background: var(--cu-black-50);   
            }
            img {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    } 

    &.no-tracks {
        .list-body {
            display: none;
        }

        .controller-panel {
            pointer-events: none;
            filter: opacity(0.2);
        }
    }
}

.music-player.collectionlevel {
    padding-right: 1rem;    

    .list-player {    
        .list-body{
            background: var(--cu-black-500);
        }
        .list-header {            
            display: none;            
        }

        .track {
            cursor: pointer;
            :is(&.selected,&:hover){
                background: var(--cu-black-trans-hover);
            }
            .meta-cont {
                display: none;
            }

            span.number {
                font-size: initial;
            }
        }        
    }
}