.discover-collection-container {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: max-content;
        .image-item {
            border-radius: 0.28571rem;
            max-height: 25rem;
            aspect-ratio: 1;
            width: 100%;
            .image-container{
                height: var(--discover-collection-card-height);
            }
            .card-drop {
                border-radius: 0.25rem;
                border: 1px solid var(--cu-black-900);
                background: var(--cu-black-100);
            }
            .drop-name, .image-text {
                color: var(--cu-white-900);
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 0.85714rem;
                letter-spacing: 0.02rem;
            }
            .image-container {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 0.25rem;
                transition: background-image 1s ease;
                &:hover{
                   transition: 1s ease;
                }
                img.card-image {
                    border-radius: 0px 0px 4px 4px;
                    margin: auto;
                }
            }
            .image-text {
                font-weight: 500;
            }
            .community-pic {
                img {
                    border-radius: 2.14286rem;
                    border: 1px solid var(--cu-black-100);
                    width: 1.875rem;
                    height: 1.875rem;
                    object-fit: cover;
                    @media screen and (max-width: 550px){ 
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
                
            }
        }
}


.discover-selected-collection { 
    .dicover-collection-lg{
        padding: 0rem !important;
        &.grid-sm{
            grid-template-columns: repeat(5, 1fr);
        }
        .nft-gallery-card-image {
            height: 100%;
        }
    }
}
.discover-collection-container {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: max-content;
    display: grid;
    gap:0.75rem;
    @media screen and (max-width:550px){
        gap: 0.90625rem 0.75rem;
    grid-template-columns: repeat(auto-fill,minmax(46%,1fr));
    }
    .discover-collection-card {
        display: flex;
        gap: 0.90625rem;
        flex-direction: column;
        width: 100%;
        .image-container {
            height:100%;;
            width: 100%;
            .image-bg {
                width: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                height: 100%;
                border-radius: 0.28571rem;
            }
            
        }
        .caption-container{
            display: flex;
            gap: 0.71429rem;
            align-items: center;
            padding: 0.5rem 0.42857rem;
            width: auto;
            .caption {
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0.02rem;
                text-align: left;
            }
            .count {
                padding: 0.1rem 0.3rem;
                background-color: var(--cu-black-400);
                border-radius: 0.28571rem;
                span {
                    font-size: 0.85714rem;
                    line-height: 1rem; /* 116.667% */
                    letter-spacing: 0.01714rem;
                    background: -webkit-linear-gradient(#00C5FF, #4745ED);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}
.dis-creator-collection {
    padding: 0;    
    padding-bottom: 1rem;
    .right-panel-container {
        padding: 0;
        overflow-y: unset;
    }
}
.discover-colelction-detail-panel {
    .list-right-panel {
        padding: 0;
        padding-bottom: 1.42857rem;
        .right-panel-container {
            padding: 0;
            .collection-image-cotainer {
             
                img {
                    object-fit: cover;

                }
            }
        }
    }
    .close-section {
        display: none;
    }
    &:hover{ 
        .close-section {
            display: block;
        }
    }
}

.close-button img {
    height: 2.5rem;
    border: 1px solid #30333A;
    border-radius: 6px;
    padding: 0.5rem 0.5rem;
    width: auto;
}

.gallery-new {
    padding-left: 0;
}

.gallery-second-level {
    padding-right: 0 !important;
}

.collection-card {
    cursor: inherit;
}

.nft-selected-name .name-lg {
    text-align: left;
    max-width: 100%;
    width: 100%;
    font-size: .85rem;

}
.tab-styles {

    background: var(--neutral-900);
    .tab-data {
      border-bottom: solid 1px var(--cu-black-10);;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--cu-black-10);
      font-size: .875rem;
      line-height: 1.21429rem;
      background: var(--neutral-900);

      .tab{
        font-size: 0.875rem;
      line-height: 1.21429rem;
      padding-bottom:  .625rem;
      box-sizing: border-box;
      position: relative;
      &.not-active{
        &::after{
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          background: var(--cu-white-200);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover{
          color: var(--cu-white-200);
          &::after { 
          width: 100%; 
          left: 0; 
        }}
      }
      &.active {
        color: var(--cu-white-200);
        border-bottom: solid 1px var(--cu-white-200);

      }
      }

    }
  }