.community-pro{
  @media screen and (max-width: 550px) {
    border:none;
  }
}
.create-edit-community {
  .button{
    @media screen and (max-width: 550px) {
      font-size: 0.75rem;
      font-weight: 400;
    } 
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 600;
  }
  .section-title {
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 550px) {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  .brd-btm {
    padding-bottom: 0.625rem;
    @media screen and (max-width: 550px) {
      border-bottom:none;
      padding-bottom: 0.5rem;
    }
  }
  label {
    font-size: 0.625rem;
    font-weight: 400;
    color: var(--cu-white-100);
  }
  :is(input, textarea)::placeholder {
    color: var(--cu-black-10);
  }
  input,
  textarea {
    background-color: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    color: var(--cu-white-200);
    font-size: 0.625rem;
  }

  textarea {
    max-height: 8rem;
    min-height: 6rem;
    border-radius: 12px !important;
    padding: 0.5rem 0.8rem !important;
  }
  input {
    border-radius: 50px !important;
    padding: 0.4rem 0.8rem !important;
  }

  .upload-banner-image {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    aspect-ratio: 5/1;
    height: 8rem;
    label {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .upload-icon {
      display: none;
      background-color: var(--cu-black-700);
      margin: auto;
    }
    .dummy-wrap{
      p{
        text-align: center;
        font-size: 0.75rem;
        color: var(--cu-black-10);
      }
      .image-uploaded{
        @media screen and (max-width: 550px) {
          height: 2.125rem;
          width: 2.125rem;
          }
      }
      &.long-banner{
.image-uploaded{
  @media screen and (max-width: 550px) {
    height: 3.75rem;
    width: 3.75rem;
    }
}
      }
    }
    .image-uploaded {
      padding: 0px;
      margin: auto;
      object-fit: cover;
      width: 100%;
      height: 100%;
      &.actual-img{
        width: 100%;
        object-fit: cover;
        border-radius: 22px;
      }
    }
    &.profile-img{
      width: 8.75rem;
      height: 8.75rem;
      border-radius: 5rem;
      overflow: hidden;
      @media screen and (max-width: 550px) {
        margin-right: auto;
        margin-left: initial;
      }
      .image-uploaded {
        max-height: 100%;
        object-fit: cover;
      }
    }
    &:hover {
      .upload-icon {
        display: inline;
      }
      .dummy-wrap,.image-uploaded {
        display: none;
      }
    }
  }
  .upload-thumbnail-image {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    min-height: 9rem;
    max-height: 9rem;
    width: 14rem;
    max-width: 14rem;
    label {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .upload-icon {
      display: none;
      background-color: var(--cu-black-700);
      margin: auto;
    }
    .dummy-wrap{
      p{
        text-align: center;
        font-size: 0.75rem;
        color: var(--cu-black-10);
      }
      .image-uploaded{
        @media screen and (max-width: 550px) {
          height: 2.125rem;
          width: 2.125rem;
          }
      }
      &.long-banner{
.image-uploaded{
  @media screen and (max-width: 550px) {
    height: 3.75rem;
    width: 3.75rem;
    }
}
      }
    }
    .image-uploaded-thumbnail_img {
      position: absolute;
      padding: 0px;
      margin: auto;
      overflow: hidden;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 22px;
    }
    &.profile-img{
      width: 8.75rem;
      height: 8.75rem;
      border-radius: 5rem;
      overflow: hidden;
      @media screen and (max-width: 550px) {
        margin-right: auto;
        margin-left: initial;
      }
      .image-uploaded {
        max-height: 100%;
        object-fit: cover;
      }
    }
    &:hover {
      .upload-icon {
        display: inline;
      }
      .dummy-wrap,.image-uploaded,.image-uploaded-thumbnail_img {
        display: none;
      }
    }
  }
  .new-checkbox span {
    background: var(--cu-black-700);
    border: 1px solid var(--cu-black-100);
    border-radius: 4px;
    color: var(--cu-black-10);
    padding: 0.2rem 0.625rem;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &.active {
      border-color: var(--cu-blue-60);
    }
  }
  .community_btn-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1.5rem 0 0 0;
    .save-btn, .cancel-btn {
      width: 40%;
      font-size: 0.875rem;
      padding: 0.65rem 1rem;
      border-radius: 5rem;
      transition: all 0.3s;
      color: var(--cu-white-900);
      border: none;
      box-shadow: 0 0 0 1px var(--cu-black-100);
      &:hover {
        background: var(--dark-mode-300);
      }
    }
    .save-btn {
      background: var(--dark-mode-900);
    }
  }
  &.create {
    h3 {
      font-size: 1.375rem;
      font-weight: 600;
    }
    textarea {
      max-height: 33.5rem;
      resize: none;
      @media screen and (max-width: 550px) {
        max-height: 7.5rem;
      }
    }
    .new-checkbox span {
      color: var(--cu-white-900);
    }
    .sub-title{
      font-size: 0.75rem;
    }
  }
  @media screen and (max-width: 550px) {
    &.create {
        .button{
            width: 100%;  
        }
    }
  }
}

.recomended-size{
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--cu-white-100);
}
