
    .asset-listing-loader{
        &.skelton-card{
        grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
        gap: 0.45625rem;
        row-gap: 1rem;
        .react-loading-skeleton{
            aspect-ratio: 1;
        }
        }
    }
    body {
        counter-reset: itemCounter;
      }
      .hover-effect {
      
        aspect-ratio: 1;

        
        // -webkit-mask: 
        //   linear-gradient(135deg,#000c 40%,#000,#000c 60%)
        //   100% 100%/250% 250%;
        transition: .4s;
        cursor: pointer;
      }
      .alt-class {
        --r: 1,1;
        // -webkit-mask: 
        //   linear-gradient(45deg,#000c 40%,#000,#000c 60%)
        //   0 100%/250% 250%;
      }
      .hover-effect:hover {
      
        // scale: 1.1;
        --i: -1;
        z-index: 40;
        overflow: visible;
        opacity: 1;
        -webkit-mask-position: 0 0;
      }
      .alt-class:hover {
        -webkit-mask-position: 100% 0;
        overflow: visible;
        opacity: 1;
        -webkit-mask-position: 0 0;
      }

      .hover-effect2 {
        --a: 8deg; /* control the angle of rotation (the smaller, the better) */
        width: 250px;
        aspect-ratio: 1;
        border-radius: 20px;
        
        // -webkit-mask: 
        //   linear-gradient(135deg,#000c 40%,#000,#000c 60%)
        //   100% 100%/250% 250%;
        // transition: .4s;
        cursor: pointer;
      }
      .hover-effect2:hover {
        transform: perspective(400px) rotate3d(var(--r,1,-1),0,calc(var(--i,1)*var(--a)));
        scale: .9;
        --i: 1;
        -webkit-mask-position: 0 0;
      }
      .gallery-asset-level{
        &.skelton-card{
          grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
          .react-loading-skeleton{
            aspect-ratio: 1;
          }
        }
      }