.collectionTable {
    th{
        color: var(--neutral-500);
        font-weight: 400;
        font-size: 0.875rem;
    }
    @media screen and (max-width: 550px) {
    th:not(:first-child,:nth-child(2),:nth-child(3)) {
        display: none;
    }
    th:not(:first-child) {.head-div {
        justify-content: end;
    }
}
}
}
.collectionTable {
    th:first-child, td:first-child {
        text-align: left;
        word-break: break-word;
        white-space: unset;
        .head-div{
            justify-content: start;
        }
        
    }
    td {
        text-align: right !important;
        padding: 1rem 0.938rem;
        color: var(--cu-white-700);
        .count-layout {
            display: flex;
            padding: 0.28571rem 0.42857rem;
            justify-content: center;
            align-items: center;
            border-radius: 0.25rem;
            margin-left: auto;
            width: max-content;
            span {
                font-size: 1rem;
                background: var(--cu-white-700);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 500;
            }
        }
    }
    th {
        .head-div{
            justify-content: space-between;
        }  
    }
    .tbl-content {
        // height: calc(100vh - 12rem);
        td{
            .list-name-wid-name{
                display: flex;
                align-items: center; 
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding: 0.65rem;
               
            }
            .list-name-wid{
                display: block;  
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .collection-table-image {
                flex: 1 0 0;
                align-self: stretch;
                height: 3.2rem !important;
                width: 3.2rem !important;
                border-radius: .25rem;
            }
            .collection-table-image-nft {
                flex: 1 0 0;
                align-self: stretch;
                height: 3.2rem !important;
                width: 3.2rem !important;
                border-radius: .25rem;
            }

            .policy-id {
                color: var(--cu-black-5);
            }
            .filter {
                filter: invert(67%) sepia(9%) saturate(723%) hue-rotate(176deg) brightness(130%) contrast(93%);
            }
        }
        @media screen and (max-width: 550px) {
            .customeTable{
            td:not(:first-child,:nth-child(2),:nth-child(3)) {
                display: none;
            }
            td:not(:first-child) {
                text-align: end;
            }
        }
        }
    }
}
.list-active {
    width: 28rem !important;
}
.collectionTable2 {
    position: absolute;
    width: 98%;
    height: calc(100vh - 22rem);
    // border-right: 1px solid var(--cu-black-trans-250);
    // border: 1px solid red;
    margin: -0.5rem 0rem 0 0;
    // padding-right: 5%;
    overflow: scroll !important;
    &::-webkit-scrollbar {
        width: 5px;;
    }
    &::-webkit-scrollbar-track:vertical{
        margin-top: 4rem;
    }
    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--cu-black-40);
        border-radius: 10px;
    }
    th {
        color: var(--neutral-500);
        font-weight: 400;
        font-size: 0.875rem;
    }
    .tbl-content {
        z-index: 1;
        td{
            z-index: 1;
            .list-name-wid-name{
                display: flex;
                align-items: center; 
                // text-overflow: ellipsis;
                // overflow: hidden;
                // white-space: nowrap;
                padding: 0 0.65rem;  
            }
            .list-name-wid{
                display: block;  
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .collection-table-image {
                flex: 1 0 0;
                align-self: stretch;
                height: 3.2rem !important;
                width: 3.2rem !important;
                border-radius: .25rem;
            }
            .collection-table-image-nft {
                flex: 1 0 0;
                align-self: stretch;
                height: 3.2rem !important;
                width: 3.2rem !important;
                border-radius: .25rem;
            }

            .policy-id {
                color: var(--cu-black-5);
            }
            .filter {
                filter: invert(67%) sepia(9%) saturate(723%) hue-rotate(176deg) brightness(130%) contrast(93%);
            }
        }
        @media screen and (max-width: 550px) {
            .customeTable{
            td:not(:first-child,:nth-child(2),:nth-child(3)) {
                display: none;
            }
            td:not(:first-child) {
                text-align: end;
            }
        }
        }
    }
}