.pagination {
    ul {
        display: flex;
        gap: 0.57rem;
        flex-wrap: wrap;
        @media screen and (max-width:450px){
            gap: 0rem;   
        }
        li {
            
            min-width: 2rem;
            min-height:2rem ;
            color: var(--cu-black-10);
            font-size:.875rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width:550px){
                font-size: 0.75rem;
            }
            &.selected {
                color: var(--neutral-100, --cu-white-550);
                border-radius: 4px;
                background: var(--neutral-700, #323A46);
            }
        }
    }

    .previous,
    .next {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-left,
    &-right {
        display: block;
        width: 1.3rem;
        height: 1.3rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        opacity: 0.3;
        @media screen and (max-width:550px){
            width: 1rem;
            height: 1rem; 
        }
    }

    &-left {
        background-image: url(~/src/Assets/Images/pagination-left-active.svg);
    }

    &-right {
        background-image: url(~/src/Assets/Images/pagination-right-active.svg);
    }

    &-total {
        font-weight: 500;
        color: var(--at-gray-810);
    }

    .previous {
        &.disabled {
            .pagination-left {
                background-image: url(~/src/Assets/Images/pagination-left-inactive.svg);
            }
        }
    }

    .next {
        &.disabled {
            .pagination-right {
                background-image: url(~/src/Assets/Images/pagination-right-inactive.svg);
            }
        }
    }
}

span.pagination-break::before {
    content: '. . .'
}