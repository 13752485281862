.page-listing-community {
  &.home-grid {
    &.list-container {
      .categories {
        .detail-name {
          display: inline-block;
          width: 100% !important;
          line-height: unset !important;
          font-size: 0.875rem !important;
          color: var(--neutral-500, #64748b);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .hover-community {
    display: flex;
    position: absolute;
    inset: 0;
    opacity: 0;
    //height: 0rem;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 0.42857rem;
    padding: 1rem;
    border: 1px solid var(--cu-black-400);
    background: var(--cu-black-410);
    transition: all 0.5s ease-in-out;
    .collection-box {
      display: grid;
      gap: 0.5rem;
      grid-template-columns: repeat(6, 1fr);
      .collection-img {
        // height: var(--collection-sm-card-height);
        aspect-ratio: 1;
        border-radius: 0.25rem;
        overflow: hidden;
        transition: 0.4s 0.2s ease;
        scale: 0.7;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    p {
      text-align: left;
      font-size: 0.875rem;
      color: var(--cu-white-550);
      transition: 0.4s 0.2s ease-in-out;
      scale: 0.7;
      &.description {
        min-height: 3.375rem;
      }
    }
    .details-section {
      gap: 0.5rem;
      .details-item {
        font-size: 0.875rem;
        color: var(--cu-black-5);
        transition: 0.4s 0.2s ease-in-out;
        scale: 0.7;
        .item-count {
          font-size: 0.875rem;
          color: var(--cu-white-550);
          transition: 0.4s 0.2s ease-in-out;
          scale: 0.7;
        }
        padding: 0rem;
      }
    }
  }
  .community-box-wrapper {
    transition: all 0.5s ease-in-out;
    &:hover {
      .hover-community {
        //transition: all 0.5s ease-in-out;
        opacity: 1;
        height: 100%;
        width: 100%;
        :is(.collection-img, p, .details-item, .item-count) {
          scale: 1;
        }
      }
    }
  }
}
.community-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  row-gap: 1rem;
  margin-top: 1rem;
}
