.nav-bar {
    max-height: calc(100%);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &.active{
        max-height: calc(100% ) !important;
    }
    .divider{
        height: 1px;
        width: 100%;
        border-color: var(--cu-black-100);
        margin: 0.25rem 0rem 0.2rem 0rem;
    }
    .nav-title-box {
        display: flex;
        background: var(--cu-black-500);
        padding-left: 2rem;
        width: 100%;

        .nav-title {
            border-bottom: 0.0625rem solid var(--cu-black-900);
            padding: 0;
            width: 10.75rem;
            height: auto;
            text-align: left;
            padding-top: 3.0625rem;
            padding-bottom: .75rem;

            p {
                padding: 0;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.1875rem;
            }
        }
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.625rem;
    gap: 0.625rem;
    @media screen and (max-width: 550px){
        gap: 1rem;
    }
    /* Dark Mode / 900 */

    background: var(--cu-black-500);
    .first-nav {
        padding: 0.625rem 0.75rem 0.75rem 1.4rem;
        width: 100%;
        border-bottom: none;
        border-left: none;
        border-right: none;
        min-width: 88px;
        animation: mymove 0.5s linear;
        @keyframes mymove {
            0%   {
                transition: all 2s;
                transition-delay: 2s;
                min-width: 85px;
                padding: 0.625rem 0rem 0.75rem 1.2rem;
            }
            50%  {  
                transition: all 3s;
                min-width: 85px;
                transition-delay: 2s;
                padding: 0.625rem 0rem 0.75rem 1.4rem;
            }
            75%  { transition: all 4s;
                min-width: 85px;
                transition-delay: 2s;
                padding: 0.625rem 0rem 0.75rem 1.4rem;}
            
            100%  {  
                min-width: 85px;
                transition: all 4s;
                transition-delay: 2s;
                padding: 0.625rem 0rem 0.75rem 1.4rem;}
             }
        &.active { 
            padding-left: 0.8rem;
        }
        .active {
            left: 5.5rem;
            transition-delay: 0s;
            transform: rotateY(180deg);
        }
    }
    .second-nav {
        padding: .625rem 0.75rem 0.75rem 2.30rem;    
        width: 100%;
        border: 1px solid var(--cu-black-100);
        border-bottom: none;
        border-left: none;
        border-right: none;
        border-top: none;
        &.active { 
            padding-left: 0.8rem;
        }
        .active {
            left: 5.5rem;
            transition-delay: 0s;
            transform: rotateY(180deg);
        }
    }
    .collapsed-nav{
        padding-bottom: 0.75rem;
        padding-top: 0.625rem;
    }
    .third-nav {
        // border-top: 2px solid var(--cu-black-100);
        // padding-top: .5rem;
    }
    .expand-text{
        overflow: unset !important;
        transition: all 1s !important;
        transition-delay: 2s;
    //     animation: mymove 0.5s linear;
    // @keyframes mymove {
    //     0%   {
    //        transition: all 1s;
    //     }
    //     50%  {  
    //         transition: all 2s;
    //     }
    //     75%  { transition: all 3s;}
        
    //     100%  {  transition: all 4s;}
    //      }
    }
    .menu-icon {
        width: 1.125rem;
        height: 1.125rem;
    }
    .menu-icon-arrow{
        width: 0.75rem;
        height: 0.75rem;
        &.menu-icon-arrow-active {
            transform: rotate(90deg);
        }
    }
    .menu-icon-top {
        width: 1.71429rem;
        height: 1.71429rem;
    }



    li {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        width: 100%;
        font-size: 1rem;
        cursor: pointer;
        padding: 0 .55rem 0 .75rem;
    }

    a,
    .nav-parent {
        padding: 0.625rem 0.75rem 0.75rem 1.4rem;
        width: 100%;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.1875rem;
        justify-content: space-between;
        border-radius: .25rem;
        &:hover {
            background: var(--cu-black-50) !important;
        }
        @media screen and (max-width: 550px){
            padding: 0.75rem 0.75rem .5rem 2.29rem;
        }
    }
    .menu-section{
        @media screen and (max-width: 550px){
        border-bottom: 1px solid var(--cu-black-100);
        padding-bottom: 1rem !important;
        margin-bottom: 0.4rem;
        }
    }

    .nav-child {
        a {
            font-size: .875rem;
            font-weight: 400;
            line-height: 1.0625rem;
            padding: 0.625rem 0.75rem 0.75rem 3.15rem

        }
        .link-active2 {
            background: var(--cu-black-50);
            border-radius: .25rem;
            margin: .0rem .75rem 0rem .75rem;
            padding: 0.625rem 0.75rem 0.75rem 2.4rem;
            @media screen and (max-width: 550px) {
                margin: 0;
                background: none;
            }
        }

    }

    .link-active {
        background: var(--cu-black-50);
        border-radius: .25rem;
        padding: .625rem 0.75rem 0.75rem 1.4rem;

    }


    .expand-section {
        position: absolute;
        left: 14.5rem;
        top: 50%;
        background-color: #00264D;
        border-radius: 1.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 1.875rem;
        display: flex;
        align-items: center;
        transition: all .75s;
        transition-delay: .5s;
        z-index: 1;
        width: 1rem;
        @media screen and (min-width: 768px) and (max-width: 1024px) { 
            left: 10.95rem;
        }
        &.active {
            left: 5.5rem;
            transition-delay: 0s;
            transform: rotateY(180deg);
        }
    }

    .menu-text {
        transition: all 0.5s;
        transition-delay: 1s;
        overflow: hidden;
        padding-bottom: 2px;
        font-size: 0.9375rem;
        &.main-text {
            color: var(--cu-white-100);
            font-size: 0.8125rem;
            font-family: 400;
            display: flex;
            justify-content: center;
            padding-top: .625rem;
        
        }
        &.inactive {
            max-width: 0;
            opacity: 0;
            max-height: 0;
            &.childul {
                position: absolute;
                max-width: unset;
                position: absolute;
                z-index: 4;
                left: 2rem;
                background: var(--cu-black-500);
                max-width: unset;
                opacity: 1;
                border: 1px solid var(--cu-black-100);
                border-radius: 0.75rem;

                li {
                    padding: 0.625rem 1rem 0.625rem 0.625rem;
                    margin: 0.625rem 0;

                    &:hover {
                        background-color: var(--cu-black-50);
                    }

                    &.active-li {
                        background-color: var(--cu-black-50);
                    }
                }

                a {
                    padding: 0;

                    &.link-active {
                        background: unset;
                    }
                }
            }
        }
        &.menu-sub-nav{
            @media screen and (max-width: 550px) {
                display: flex;
                gap: 0.625rem;
                padding-left: 3.75rem;
                padding-bottom: 0.5rem;
                li{
                    padding: 0rem;
                    width: auto;
                    a{
                        padding: 0rem; 
                        div {
                            font-size: 0.75rem;
                        } 
                    }
                }
            }
        }
 
    }

    .menu-collpased-overlay {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 3;
        top: 0;
        bottom: 0;
    }
    .nav-button-wrapper{
        margin: 0.25rem 2rem 0.5rem 2rem;
        width: calc(100% - 4rem);
        button{
            width: 100%;
            border-radius: 0.75rem;
            font-size: 0.75rem;
        }
    .trial-button button {
        border-color: var(--cu-black-30);
        color: var(--cu-black-5);
    }
}
    @media screen and (max-width: 550px) {
        width: 100%;
        height: 100%;
        max-height: 100%;

        >ul>li {
            &:first-child {
                a {
                    // padding-top: 1.25rem;
                }
            }
        }

        a,
        .nav-parent,
        .nav-child {
            line-height: .875rem;
            font-size: .875rem;
            padding-left: 2rem;

            a {
                font-size: .75rem;
                padding-right: 1rem;
                padding-left: 2.5rem;
            }
        }

        a {
            padding: .625rem 0.75rem 0.75rem 1.9rem;

        }
    }
    .app-link-wrapper{
        width: calc(100% - 4rem);
        margin: 0.5rem 2rem;  
        .app-link{
            font-size: 0.625rem;
        }
    }
    .social-media{
        display: flex;
        gap: 1rem;
        justify-content: center;
        width: calc(100% - 4rem);
        align-items: center;
        margin: 0rem 2rem;
        margin-bottom: 0.625rem;
        a{
            padding: 0rem;
            line-height: 0;
        }
    }
    .border-bottom-nav{
        width: 100%;
        border-bottom: 1px solid var(--cu-black-100);
    }
}

.unordered-list {
    overflow: hidden;
    padding-right: .25rem;
    display: flex;
    flex-direction: column;
    gap: 3px;
    &:hover {
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 0rem;
    }
    
}
.p-0-imp {
    padding: 0 !important;
}