.gallery-control-container {
    .control-card {
        display: flex;
        padding: 1.143rem;
        border-radius: 0.429rem;
        background: var(--dark-mode-900);
        gap: 1rem;
    }
    .control-card.pop {
        position: absolute;
        border: unset;
        padding: 1.143rem;
        opacity: 0;
        animation: fadeInControls 0.5s forwards;
        bottom: 4rem;
        margin: auto;
    }
    .control-body {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
    .controls-title {
        display: flex;
        color: var(--neutral-100);
        font-size: 1.14286rem;
    }
    .controls-content {
        display: flex;
        gap: 1rem;
        padding: 0 1.5rem;
        span {
            color: var(--neutral-500);
            font-size: 0.75rem;
            &.text-active {
                color: var(--neutral-100);
                font-weight: 600;
            }
        }
        
    }
    .display-list-control {
        &.show-display-control {
            display: flex;
        }
        &.hidden-display-control {
            display: none !important;
        }
        .display-filter {
            .filter-img {
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 0.25rem;
                }
            }
        }
        .display-content {
            max-height: 12rem;
            .display-list-item {
                &.selected {
                    border-color: var(--cu-white-900);
                }
                .gallery-images-position {
                    display: flex;
                }
                .control-overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    &.disabled {
                        background: #060606bd;
                    }
                    &.play-hover:hover {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #060606bd;
                        .play-image {
                            opacity: 1;
                            img {
                                width: 1.7rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-style-control {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .custom-styles {
            animation: fadeInControls 1s forwards;
        }
        .bottom-border {
            border-bottom-width: 1px;
            border-bottom-color: #30333A;
        }
        .layout-content {
            padding: 0;
            gap: 2.5rem;
            .layout-items {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                gap: 1rem;
            }
        }
        .preview-content {
            .preview-item {
                width: 100%;
                min-height: 4rem;
                border: 1px solid;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                .preview-box {
                    border-radius: 2px;
                    background: var(--cu-white-900);
                    &.sq {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                    &.port {
                        width: 2.8rem;
                        height: 3.2rem;
                    }
                    &.land {
                        width: 4.5rem;
                        height: 2rem;
                    }
                }
            }
        }
    }
    .layout-list-control {
        .layout-content {
            padding: 0;
            display: block;
            .layout-sec-list {
                .layout-image {
                    img {
                        border-radius: 2px;
                    }
                    &.active {
                        border: 1px solid var(--cu-white-900);
                        border-radius: 2px;
                    }
                }
            }
        }
    }
    .mbg-list-control {
        .mbg-filter {
            .style-box {
                border-right: 1px solid var(--cu-black-100);
                color: var(--neutral-500);
                cursor: pointer;
                font-size: .85rem;
                padding: 0 0.375rem;
                &.active {
                    color: #fff;
                }
                &:last-child {
                    border-right: 0px
                }
            }
            .color-wrapper {
                width: 2.28rem;
                height: 2.28rem;
                border-radius: 4px;
                background: linear-gradient(90deg, #FFF -0.19%, #000 50%);  
                cursor: pointer;
                .color-list {
                    position: absolute;
                    right: 0rem;
                    z-index: 99;
                    top: 24px; 
                    padding: 1rem 1.25rem;
                    background: var(--cu-black-500);
                    border-radius: 0.75rem;
                    width: 10.125rem;
                    border: 1px solid var(--cu-black-100);
                    .close-icon {
                        width: 1rem;
                    }
                    .color-list-wrapper{
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(1rem, 1fr));
                        gap: 0.5rem;
                    }
                    .color-circle {
                      width: 1rem;
                      height: 1rem;
                      border-radius: 0.125rem;
                      cursor: pointer;
                      border: 1px solid var(--cu-black-500);
                      outline: 1px solid transparent;
            
                      &.multi {
                        background: linear-gradient(red, yellow, blue, orange);
                      }
            
                      &.selected {
                        outline-color: var(--cu-white);
                      }
                    }
                  
                }
            }
        }
        .mbg-content {
            padding: 0;
            display: block;
            .background-item {
                img {
                    border-radius: 2px;
                }
                &.active {
                    border: 1px solid var(--cu-white-900);
                    border-radius: 2px;
                }
            }
        }
    }
    .listing-grid-control-panel {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
        gap: 0.5rem;
        padding: 0;
        max-height: 10rem;
        overflow-y: auto;
    }
}
@keyframes fadeInControls {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}