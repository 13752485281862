.wall-filter {
  .no-color{
    color: var(--cu-black-10);
    font-size: 0.75rem;
  }
  .style-box {
    font-size: 0.625rem;
    color: var(--neutral-500);
    padding: 0rem 0.4125rem;
    border-right: 1px solid var(--cu-black-100);
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
    &.active {
      color: var(--cu-white-200);
    }
  }
  .color-wrapper {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    padding: 0.25rem 0.375rem;
    border-radius: 0.25rem;
    border: 1px solid var(--neutral-500);
    cursor: pointer;
    .color-text {
      font-size: 0.625rem;
      color: var(--cu-white-100);
    }
    .color-show {
      display: flex;
      width: 0.9375rem;
      height: 0.9375rem;
      border-radius: 0.125rem;
      align-items: center;
      &.multi {
        background: linear-gradient(red, yellow, blue, orange);
      }
    }
    .color-list {
        display: flex;
        align-items: center;
        padding: 1rem;
        position: absolute;
        right: 0rem;
        z-index: 99;
        top: 24px; 
        background: var(--dark-mode-900);
        border-radius: 12px;
        border: 1px solid var(--dark-mode-300, #30333A);
        width: 6.7rem;
        height: auto;
        .color-list-wrapper{
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          align-items: flex-start;
          align-self: stretch;
          flex-wrap: wrap;
          column-gap: 4px;
          row-gap: 1px;
        }
        .color-circle {
          width: 1rem;
          height: 1rem;
          border-radius: 0.125rem;
          cursor: pointer;
          border: 1px solid var(--cu-black-500);
          outline: 1px solid transparent;

          &.multi {
            background: linear-gradient(red, yellow, blue, orange);
          }

          &.selected {
            outline-color: var(--cu-white);
          }
        }
      
    }
  }
}
