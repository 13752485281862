.details-skeleton{
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    grid-template-rows: max-content;
    &.large-view{
        grid-template-columns: repeat(4, 1fr);  
    }
    &.collection-loader{
        .skeleton-wrapper{
            aspect-ratio: 0.94;
        }
    }
    &.profile-img-with-2-lines{
        width: 50%;
        grid-template-columns:unset;
        .skeleton-wrapper{
            aspect-ratio: unset;
        }
        .details-wrapper{
            align-items: start;
            .text-details span:nth-child(2){
                width: 75%;
            }
        }
    }
    &.banner-profile-skeleton{
    display: flex;
    width: 100%;
    .banner-profile-img{
        position: absolute;
        left: 1.15rem;
        bottom: 1.5rem;
      .react-loading-skeleton{
        &::after{
            animation-duration: 1s;  
        }
      }  
    }
    .skeleton-wrapper{
        aspect-ratio: unset;
    }

    }
    &.sk-three-six{
            aspect-ratio: 3.5;

    }
    .skeleton-wrapper{
        height: fit-content;
        aspect-ratio: 1;
        span{
            &.flex{
                height: -moz-available;          /* WebKit-based browsers will ignore this. */
                height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                height: fill-available;
                height: 100%;
            }
        }
        .details-wrapper{
          .text-details{
            width: 100%;
            span{
                width: 100%;
            }
          }  
        }
    }
    &.aspect-pt-six{
        .skeleton-wrapper{
            aspect-ratio: 1/0.6;
        }  
    }
    &.aspect-pt-six-three{
        .skeleton-wrapper{
            aspect-ratio: 1/0.63;
        }  
    }
    &.aspect-pt-five-six{
        .skeleton-wrapper{
            aspect-ratio: 1/0.565;
        } 
    }
    &.aspect-unset{
        .skeleton-wrapper{
            aspect-ratio: unset;
        }
    }
    &.details-gap{
        .text-details{
            margin-top: 0.4rem;
        } 
    }
}