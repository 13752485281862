.banner {
    background-color: var(--cu-black-500);
    display: flex;
    align-items: flex-start;
    padding: .5625rem 2rem .5625rem 1rem;
    flex: 1;
    width: 100%;
    min-height: 3rem;

    @media screen and (max-width: 550px) {
        padding-right: 1rem;
        min-height: 3rem;
    }

    .arrow {
        padding-top: 0.2rem;
    }

    &.gallery-banner-sm {
        @media screen and (max-width: 550px) {
            padding-left: 1rem;
            display: block;
            left: 0;
            right: 0;
            position: fixed;
            top: 3.125rem;
            padding-left: 1rem;
            z-index: 4;
            align-items: center;
            display: flex;
        }
    }

    .gallery-head-container {
        align-items: center;
        justify-content: space-between;
        flex: 1;
    }

    .gallery-sm-head-container {
        display: none;

        @media screen and (max-width: 550px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .gallery-sm-icons {
            @media screen and (max-width: 550px) {
                display: flex;
                align-items: center;
                gap: 1rem;
            }

            .sort-filter-sm {
                @media screen and (max-width: 550px) {
                    position: absolute;
                    background: var(--cu-black-90);
                    box-shadow: 0px 2px 4px var(--cu-black-trans-200);
                    border-radius: 0.25rem;
                    top: 3rem;
                    right: 1.125rem;
                    width: 12rem;
                    padding: 0.3rem 0rem;
                    word-break: break-all;
                }
            }
        }
    }

    .sort {
        .sort-section {
            @media screen and (max-width: 768px) {
                gap: 0;
            }

            @media screen and (max-width: 550px) {
                gap: 0.4rem;
                padding-top: 0;
            }
        }

        .sort-txt {
            color: var(--cu-black-10);
            padding: .25rem .25rem .25rem 0;
            font-size: 0.875rem;
            line-height: 1.0625rem;
            min-width: fit-content;
            align-items: center;
            display: flex;
        }
    }

    &.gallery-detail-page {
        min-height: 3rem;

        @media screen and (max-width: 550px) {
            padding-top: 0.5rem;
        }
    }

    .filter-toggle {
        display: none;
    }

    @media screen and (max-width: 767px) {
        .filter-toggle {
            display: block;
        }
    }
}
.gallery-banner-sm {
    @media screen and (max-width: 550px) {
        padding-left: 1rem;
        display: block;
        left: 0;
        right: 0;
        position: fixed;
        top: 3.125rem;
        padding-left: 1rem;
        z-index: 4;
        align-items: center;
        display: flex;
    }
}
.full-gallery {
    user-select: none;

    @media screen and (max-width: 550px) {
        overflow: unset;
        min-height: calc(100vh - 5rem);
    }
    .add-button {
        background-color: var(--neutral-900);
        padding: 0.42857rem 0.71429rem;
        border-radius: 0.25rem;
        border: 1px solid var(--dark-mode-300);
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        &:hover {
            background: var(--Dark-Mode-300, #30333A) !important;
        }
    }
}

.nft-gallery {
    min-height: 30rem;
    .nft-gallery-card-image-selected {
        border-radius: 0.28rem;
        border: 1px solid #20232A;
        object-fit: cover !important;
        overflow: hidden;
        &:hover {
            position: relative;
            .collection-card-overlay {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: rgba(15, 15, 15, 0.5);
                border-radius: 0.28rem; 
            }
        }
        img {
            border-radius: 0.28rem;
        }
    }
    @media screen and (max-width: 550px) {
        min-height: 10rem;
    }

    &-link {
        font-size: 0.71rem;
        text-decoration-line: underline;
        color: var(--cu-white-900);
        cursor: pointer;
    }

    &-full {
        overflow: auto;
-webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 11rem);

        @media screen and (max-width: 402px) {
            max-height: calc(100vh - 18rem);
        }
    }

    &-add {
        border: 1px solid var(--cu-black-100);
        border-radius: 0.28rem;
        cursor: pointer;

        img {
            min-width: 30%;
        }
    }

    &-cards {
        border: 1px solid var(--cu-black-100);
        background: var(--cu-black-500);
        border-radius: 0.28rem;

        img {
            flex: 100%;
        }

    }

    &-image {
        width: 100%;
        height: 70%;
    }

    &-head {
        // text-transform: capitalize;
        font-size: 0.65rem;
        color: var(--cu-white-900);
    }
}

.name-pos {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.gallery-banner-ul {
    .select-box-items {
        ul {
            max-height: 30rem;
            overflow: auto;
-webkit-overflow-scrolling: touch;
        }
    }
}

.no-data {
    color: var(--cu-black-10);
    min-height: 20rem;
    @media screen and (max-width: 550px) {
        font-size: 0.75rem;
        min-height: 15rem;
    }
}

.nft-name-gallery {
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-align: left;
    word-break: break-all;
}
.nft-gallery-count {
    border-radius: 0.25rem;
    line-height: 0.875rem;
    @media screen and (max-width: 550px) {
        //  padding:0rem 0rem 0.28571rem 0.42857rem;
         padding: 0;
         margin-top: 0rem;
         margin-bottom: 0rem;
         height: 100%;
    }
    span {
        font-size: 0.75rem;
        background: -webkit-linear-gradient( #A0ABBB,#A0ABBB);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 500;
        @media screen and (max-width: 550px) {
            font-size:0.625rem;
        }
        vertical-align: top;
    }
}
.collection-card {
    border: 1px solid var(--cu-black-100);
    border-radius: 0.25rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.add {
    border: 1px solid white !important;
    border-radius: 0.25rem;
    // border-image: linear-gradient(#00C5FF, #4745ED) 20 !important;
    // border-width: 1px !important; 
    position: relative;
    padding: 0.5px 1px 1px 1px;
}

.collection-card.active {
    border: unset;
    position: relative;
    padding: 0.2rem;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0.25rem;
        padding: 1px;
        background: linear-gradient(45deg, #00C5FF, #4745ED);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
        z-index: 9;
      }
    :hover {
        .close {
            img {
                opacity: 1;
            }
        }
    }
}

.collection-card {
    .close img {
        opacity: 0;
    }

    .selected-gallery-zoom.close img {
        opacity: 1;
    }

    :hover {
        .close {
            img {
                opacity: 1;
                margin-top: .25rem;

            }
        }
    }

    @media screen and (max-width: 550px) {
        .close {
            img {
                opacity: 1;

            }
        }
    }

}

.nft-gallery-full-card {
    // display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    padding-right: 1rem;
    align-items: flex-start;
    height: 100%;
    // padding-bottom: 1.5rem;
    &.nft-gallery{
        .card-layout-container {
            .gallery-images-position img{
                // height: var(--collection-card-height);
                align-items: start !important;
            }
        }
    }
    .collection-lvl-gallery {
       // height: var(--collection-gallery-listing-height) !important;
       aspect-ratio: 1;
        width: 100%;
        .nft-gallery-card-image-selected {
            height: 100%;
            overflow: hidden;
        }
    }
    &.list {
        overflow: hidden;
    }
    .gallery-images-position {
        width: 100%;
        height: 100%;
    }
    &.sm {
        .gallery-images-position {
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (min-width: 551px) {
        max-height: calc(100vh - 8rem);
      
    }

    @media screen and (max-width: 550px) {
        padding-right: 0.5rem;
        max-height: calc(100vh - 14rem);
        margin-right: 0rem;
    }
}

.nft-gallery-full-card-no-scroll {
    // display: flex;
    justify-content: space-between;
    
    padding-right: 2.2rem;
    align-items: flex-start;
    height: 100%;
    // padding-bottom: 1.5rem;
    &.nft-gallery{
        .card-layout-container {
            .gallery-images-position img{
                // height: var(--collection-card-height);
                align-items: start !important;
            }
        }
    }
    .collection-lvl-gallery {
      //  height: var(--collection-gallery-listing-height) !important;
      aspect-ratio: 1;
        width: 100%;
        .nft-gallery-card-image-selected {
            height: 100%;
            overflow: hidden;
        }
    }
    &.list {
        overflow: hidden;
    }
    .gallery-images-position {
        width: 100%;
        height: 100%;
    }
    &.sm {
        .gallery-images-position {
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (min-width: 551px) {
      
      
    }

    @media screen and (max-width: 550px) {
        padding-right: 0.5rem;
        max-height: calc(100vh - 14rem);
        margin-right: 0rem;
    }
}

.collapse-panel {
    transform: translateY(calc(100% - 2.6rem));

    .collapse-popup {
        transform: rotate(180deg);
    }
}

.expand-panel {
    transform: translateY(0);
    height: 20rem;

    .collapse-popup {
        transform: rotate(0deg);
    }

}

.display-txt {
    font-size: 1rem;
    padding-left: 1.2rem;
    color: var(--cu-black-10);
}

.search {
    input {
        outline: 0 !important;
        border-width: 0 0 2px !important;
        border-image: var(--cu-black-10) 1 !important;
        background-color: var(--cu-black-500) !important;
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
        border-bottom: 1px solid var(--cu-black-10) !important;
        width: 100%;
        font-size: 0.8rem;
    }
}


.search {
    width: 7rem;

    &-position {
        position: relative;
    }

    &-icon {
        position: absolute;
        top: 2px;
        right: 0px;
    }
}

.gallery-banner-button {
    flex-basis: 20rem;

    button {
        padding: 0.5rem;
    }

    &.curate-btn {
        button {
            width: auto;
        }
    }
}

.nft-gallery-count-image {
    width: 1rem;
    height: 1rem;
}

.nft-gallery-card-image {
    width: 100%;
    height: 100%;


    img {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &-selected {
        width: 100%;
        height: 10.18rem;
        display: flex;


        img {
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            height: 100%;
            border-radius: 0.28rem 0.28rem 0rem 0rem;
        }
    }

    &-select {
        font-size: 0.71rem;
        color: var(--cu-white-700);
    }

    :hover {
        color: white;
    }
}



.gallery {
    &-view {
        flex-basis: 32.64rem;
        background: var(--cu-black-500);
        padding: 1rem;
        width: 100%;
        overflow-y: auto;
        border: 1px solid var(--cu-black-100);
        padding: 0.6rem 1rem 1rem;
        border-radius: 0.25rem;

        @media screen and (max-width: 550px) {
            flex-basis: 100%;
            margin-top: 0px;
        }
    }

    &-label {
        font-size: 0.85rem;
        color: var(--cu-white-900);
        width: 6rem;
        align-items: flex-start;
        display: flex;
    }

    &-value {
        font-size: 0.85rem;
        color: var(--cu-white-900);
    }

    &-close {
        width: 1rem;
        height: 1rem;

    }

    &-scroll {
        overflow-x: auto;
        max-height: calc(100vh - 9rem);
        margin-right: 0.5rem;
    }

    &-select-view {
        margin-left: auto;
        flex: 1 0 32rem;
        max-width: 32rem;
        height: calc(100vh - 9rem);

        @media screen and (max-width: 550px) {
            max-width: 100%;
            padding: 0 0.5rem;
            height: 70vh;
        }
    }

    &-selected-image {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        width: 100%;
        padding: 0 0.6rem;
        @media screen and (max-width: 550px) {
            padding: 0 0rem;
        }
    }
}

.card-gallery {
    background-color: var(--cu-black-500);
    border: 1px solid var(--dark-mode-300);
    padding: 0px;
    border-radius: 0.42rem 0px 0px 0.42rem;
    border-right: 0px;
}

.nft-gallery-date {
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: var(--cu-black-5);
}

.image-expand {
    .expand-collapse-img {
        img {
            transform: rotate(90deg);
        }
    }
}

.image-collapse {
    .expand-collapse-img {
        img {
            transform: rotate(0deg);
        }
    }

}

.cur8-border {
    border-bottom: 1px solid var(--cu-black-900);
}

.add-nft {
    overflow: auto;
-webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 9rem);
    width: 100%;
    flex: 1 1 calc(100% - 29rem);
    &.added-nft-sm {
        max-height: calc(100vh - 5rem);
        .grid, .grid-lg {
            height: 100%;
        }
    }
    @media screen and (max-width: 550px) {
        margin-right: 0rem;
    }
}
.nft-selected-full, .preview-card-sq, .selected-pop-boxcard {
    &.slected-h-auto {
        .frame-gallery-image{
            height: auto;
        }
    }
}


.selected-gallery {
    .nft-selected-full {
        border: unset;
        background: unset;
        border-radius: unset;
        aspect-ratio: 1 / 1;
        &.active {
            .collection-card {
                // border-image: linear-gradient(#00C5FF, #4745ED) 20 !important;
                // border-width: 1px !important;  
                position: relative;
                border: 2px solid var(--cu-white-200) !important; 
                // &::before {
                //     content: "";
                //     position: absolute;
                //     inset: 0;
                //     border-radius: 0.25rem;
                //     padding: 1px;
                //     background: linear-gradient(45deg, #00C5FF, #4745ED);
                //     -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                //     -webkit-mask-composite: xor;
                //     mask-composite: exclude; 
                //     z-index: 9;
                //     @media screen and (max-width: 550px) { 
                //         z-index: 3;
                //     }
                //   }
            }
        }
        .collection-card {
           // background: var(--cu-black-700);
            display: flex;
            flex-direction: column;
            gap: 0.465rem;
            width: 100%;
            border-radius: 0.28rem !important;
            border: 1px solid #20232A !important;
            height: var(--collection-card-height);
            overflow: hidden;
            height: 100%;      
            .gallery-images-position, .nft-frame-background .gallery-nft-frame-viewer {
                height: 100%;
            }            
        }
        .gallery-nft-frame-viewer,  .nft-gallery-card-image {
            &:hover {
                position: relative;
                .collection-card-overlay {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: rgba(15, 15, 15, 0.5);
                    border-radius: 0.28rem; 
                }
            }
        }
        .nft-selected-name {
            font-size: 0.875rem;
            height: auto;
            line-height: normal;
            letter-spacing: 0.02rem;
            text-align: left;
            word-break: break-all;
            align-items: flex-start;
        }
    }
    .grid-sm {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        gap: 0.45625rem;
        max-width: 100%;
        min-height: 9rem;
        row-gap: 1rem;
        padding: 1rem 1rem 0;
        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(auto-fill,
                    minmax(25%, 1fr));
            margin-left: 0;
            padding-left: 0.5rem;
            padding-top: 0rem;
        }

        .selected-image {
            img {
                min-height: 7rem;
                width: 100%;
                object-fit: contain;
                height: 100%;
            }
        }

        &.active {
            @media screen and (max-width: 550px) {
                display: none;
            }
        }



    }

    .grid-lg {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: .5rem;
        row-gap: 1rem;
        padding: 1rem 1rem 0rem 1rem;
        
        .item {
            width: 100%;
            aspect-ratio: 1 / 1;
        }
   
        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(auto-fill,
                    minmax(48%, 1fr));
            margin-left: 0;
            padding-left: 0.5rem;
            padding-top: 0rem;
            padding-bottom: 0.6rem;
        }

        .selected-image {
            justify-content: center;
            align-items: center;
            display: flex;
            aspect-ratio: 1 / 1;

            img {
                width: 100%;
                object-fit: contain;
                height: 100%;
            }

        }

    }
    .hidden{
        display: none;
    }
}

.selected-gallery-sm,
.nft-gallery-sm {
    @media screen and (max-width: 550px) {
        margin-top: 4rem;
    }
}

.selected-gallery-sm {
    @media screen and (max-width: 550px) {
        max-height: calc(100vh - 15rem);
        padding-bottom: 3rem;
    }
}


.nft-gallery {
    .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.5rem;
        max-width: 100%;
        .grid-card {
            gap: 0.465rem;
        }
        @media screen and (max-width: 550px) {
            margin-left: 0;
            padding-left: 0.5rem;
            padding-right: 1rem;
            grid-template-columns: repeat(auto-fill, 48%);
            padding-top: 0px;
        }

        @media screen and (max-width: 425px) {
            grid-template-columns: repeat(auto-fill, 48%);
        }

        .collection-card {
            width: 100%;
            height: 100%;
        }
    }

    .grid-lg {
        display: grid;
        grid-template-columns:repeat(4, 1fr);
        gap: 0.45625rem;
        row-gap: 1rem;
        width: 100%;
        .grid-card {
            gap: 0.465rem;
        }
        @media screen and (max-width: 735px) {
            margin-left: 0.5rem;
            grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
            padding-top: 0;
        }

        .collection-card {
            width: 100%;
            height: 100%;
        }

        .nft-gallery-card-image-selected {
            width: 100%;
            height: 100%;
            overflow: hidden;
            img {
                @media screen and (max-width: 550px) {
                    // height: 10rem;
                }
            }
        }
    }
}

.added-nfts {
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(6.93rem, 1fr));
        gap: 0.5rem;
        width: 100%;


        @media screen and (max-width: 550px) {
            padding-bottom: 2rem;
            grid-template-columns: repeat(auto-fill, minmax(35%, 1fr));
        }

        .nft-gallery-card-image {
            height: 100%;
            min-height: 4rem;
        }

    }

    .grid-lg {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        gap: 0.5rem;
        width: 100%;

        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        }

        .collection-card {
            width: auto;
            height:  100%;
            display: flex;
            justify-content: center;

        }

        .gallery-images {
            width: 100%;
            min-height: 7rem;
        }
    }
    .added-card {
        // height: var(--collection-added-card-height) !important;
        aspect-ratio: 1/1;
        .gallery-nft-frame-viewer, .nft-gallery-card-image, .gallery-images-position {
            height: 100%;
        }
    }
}

.added-nft-sm {
    @media screen and (max-width: 550px) {
        display: none;
    }
}


.gallery-new-display {
    width: 100%;
}


.create-gallery-view {
    padding-left: 1rem;
    flex: 1 1 28rem;
    max-width: 28rem;

    &.create-gallery-view-sm {
        @media screen and (max-width: 550px) {
            padding: 0px;
        }

        .card-gallery {
            @media screen and (max-width: 550px) {
                background-color: unset;
                border: none;
                border-radius: unset;

                &.active {
                    background-color: var(--cu-black-70);
                    padding: 1rem;

                    .create-gallery-continer-sm {
                        height: calc(100vh - 17rem);
                        overflow: unset;
                    }
                }
            }

            &.active {
                .create-gallery-continer-sm {
                    @media screen and (max-width: 425px) {
                        height: calc(100vh - 16rem);
                        overflow: auto;
-webkit-overflow-scrolling: touch;
                    }

                    @media screen and (max-width: 375px) {
                        height: calc(100vh - 15rem);
                    }
                }
            }
        }

        .create-gallery-head {
            @media screen and (max-width: 550px) {
                display: none;
            }
        }

        .gallery-create {
            @media screen and (max-width: 550px) {
                margin: 0;
            }
        }
    }

}

.selected-gallery-button-sm {
    display: none;

    @media screen and (max-width: 550px) {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        gap: 1rem;
    }

    button {
        @media screen and (max-width: 550px) {
            font-size: 0.875rem;
        }

        @media screen and (max-width: 375px) {
            font-size: 0.8rem;
        }
    }
}

.selected-nfts {
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(5.5rem, 1fr));
        gap: 0.5rem;
        max-width: 100%;
        max-height: calc(100vh - 29rem);
        
        .collection-card {
            height: 100%;
            &.min-view {
                height: var(--gallery-min-height);
                aspect-ratio: 1/1;
            }
        }
        .gallery-images-position {
            height: 100%;
            width: 100%;
        }

    }
}

.preview-home {
    padding: 1rem;
}

.full-album:hover {
    .collection-card:hover {
        border: 1px solid var(--cu-white-900);
        background-color: var(--cu-grey-600);

        @media screen and (max-width: 550px) {
            background-color: unset;
            border: 1px solid
        }
    }
}

.select-box-new {
    min-width: 3.063rem;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--cu-white-100);
    padding-right: 0.3rem;

    select {
        border: 0;
        background-color: transparent;
        color: var(--cu-white-100);
        font-size: .625rem;
        line-height: .75rem;
        padding: .2rem;

        option {
            background-color: var(--cu-black-500);
        }
    }
}

.gallery-settings-label {
    word-break: break-all;
    width: 100%;
}

.gallery-tab {
    font-size: 1rem;
    line-height: 1.18rem;

    @media screen and (max-width: 424px) {
        font-size: 100%;
    }
}


.nft-gallery-tab.tab-page ul {
    font-size: 0.9rem;
    line-height: 1.06rem;
}


.view-images {
    height: 100%;
    width: 100%;
}

.view-gallery-dis {
    height: 38vh;
}

.full-val {
    width: 100%;
    text-align: initial;
}

.details {
    border: 0.0625rem solid var(--cu-black-30);
    border-radius: 0.25rem;
    width: fit-content;
    color: var(--cu-white-900);
    padding: .5rem;
    font-size: 1rem;
}

.nft-names {
    // padding-top: 0.14rem;
    span{
        font-size: 0.85rem;
    }
}

.preview-frame-img {
    .gallery-images-position {
        height: 100%;
    }

    .nft-frame-background {
        height: 100%;
    }
}

.zoom-preview-gallery {
    padding: 0;
    .gallery-selected-image {
        img {
            height: 100% !important;
        }
    }
}

.view-frame-images {
    .gallery-images-position {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: var(--cu-black-700);

        .gallery-selected-image {
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                height: 36.48vh;
                min-height: 20vh;
                @media screen and (max-width: 550px) {
                    object-fit: fill;
                    height: 37.48vh;
                }
            }
        }

        .loader-image {
            min-height: 10rem;
        }
    }
}

.gallery-view {
    .frame-size {
        max-height: 36.48vh !important;
    }
}

.table-image,
.view-images {
    position: relative;

    .selected-new {
        position: relative;
    }
}


.added-nfts .grid .nft-gallery-card-image {
    img {
        // max-height: 6.93rem;
        width: auto;
        object-fit: contain;
    }
}



.selected-nfts {
    .nft-gallery-card-image {
        img {
            // max-height: 5rem;
        }
    }
}

.selected-gallery-btn-sm {
    display: none;




    @media screen and (max-width: 550px) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 0.5rem 0.5rem 0 0.5rem;
        margin-top: 1rem;
        position: fixed;
        bottom: 3rem;
        left: 9px;
        right: 9px;
        background: var(--cu-black-700);
        z-index: 4;

        button {
            font-size: 0.875rem;
            margin-bottom: 0.3rem;
        }
    }
}

.preview-card-image {
    @media screen and (max-width: 550px) {
        margin-top: 0rem;
        padding: 1.2rem 1rem 0rem 1rem;
    }
}

@media screen and (max-width: 550px) {
    .hide-selected {
        display: none !important;
    }
}

.gallery-page {
    .nft-img-sec {
        object-fit: cover !important;
      }
    @media screen and (max-width: 550px) {
        padding: 0.5rem 0.625rem 0.625rem 0.625rem;
    }
}

.custome-tab {
    &.active {
        color: #14A0FA;
        text-decoration: underline;
        text-underline-offset: 0.25rem;
    }
}

.selected-nft-cards-collection {
    display: inline-flex !important;
    &.inner-nft{
      .nftListing-image{
        border-radius: 0.25rem;
      }  
    }
}

.view-gallery-full {
    padding: 0.625rem 0.625rem 1.875rem;
    gap: 1rem;
    font-size: 0.75rem;
    color: var(--cu-white-100);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.5625rem;
    max-height: calc(100vh - 34vh);
    overflow: auto;
-webkit-overflow-scrolling: touch;
}

.list-wid {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gallery-top-sm {
    @media screen and (max-width: 550px) {
        padding-top: 1rem;
    }
}

.gallery-grid-lg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
}
.curate-gallery{
    // padding-top: 0.75rem;
    .gallery-scroll{
        overflow-x: unset;
    }
    @media screen and (max-width: 550px) {
    .preview-card-image{
padding-top: 0rem;
    }
}
    .nfts-selected{
        @media screen and (max-width: 550px) {
        margin-top: 0rem;
        }
    }
    &.full-gallery{
        .selected-popup{
        height: auto;
        max-height: 100%;
        .nft-gallery-full-card{
            // overflow: auto;
-webkit-overflow-scrolling: touch;
           height: 7.8rem;

            .selected-wrapper{
                max-height: 13rem;
            }
        }
    }
        @media screen and (max-width: 550px) {
            .preview-frame-img{
            padding:0rem 1rem !important;
            .bp-none{
                padding: 0.625rem;
            }
            }
        } 
    }
    &.nft-gallery-sm {
        @media screen and (max-width: 550px) { 
            box-shadow: unset;
            padding-top: 1rem;
            margin-top: 0;
        }
    }
}
.my-gallery-banner {
    @keyframes comeToDisplay {
        0% {
        height: 0px;
        }
        50% {
        opacity: 0.5;
        }
        100% {
        opacity: 1;
        }
    }
    .visibleSlowly {
        animation: comeToDisplay 0.5s ease-out;
        overflow-y: hidden;
    }
}