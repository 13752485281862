.home-layout {
    .no-data{
        min-height: 14rem;
        @media screen and (max-width: 550px){ 
            min-height: 10rem;  
        }   
    }
    .slick-slide{
        width: auto !important;
    }
    padding: 1rem;   
    @media screen and (max-width: 550px){ 
        padding: 1.14286rem;
    }
    .title-section {
        .title {
            font-size: 1.25rem;
            color: var(--cu-white-900);
        }
        .link {
            display: flex;
            color: var(--cu-black-5);
            font-size: .75rem;
            cursor: pointer;
            align-items: center;
        }
    }
    .loader-creator-gallery {
        min-height: 16rem;
        @media screen and (max-width: 550px){  
            min-height: 5rem;
        }
        .home-skelton {
            .react-loading-skeleton {
                @media screen and (max-width: 550px){ 
                    height: 5rem !important;
                }
            }
            
        }
    }
    .hompage-gallery {
        .slick-track {
            display: flex;
            gap: 1rem;
            @media screen and (max-width: 550px){ 
                display: flex;
                margin-left: 0 !important;
                .slick-slide {
                    padding: 0 0.5rem;
                    
                }
            } 
        }
    }
    .creator-card-container {
        .slick-track {
            display: flex;
            gap: 1rem;
            @media screen and (max-width: 550px){ 
                display: block;
                .slick-slide {
                    padding: 0 0.5rem;
                }
            } 
        }
        .creator-card {
            border-radius: 0.42857rem;
            border: 1px solid var(--cu-black-400);
            padding: 0.625rem 0.875rem;
            display: flex !important;
            flex-direction: column;
            gap: 0.625rem;
            @media screen and (max-width: 550px){ 
                padding: 0.375rem;
                gap: 0.25rem;
                display: flex !important;
                justify-content: center;
            }
            &:hover {
                border: 1px solid var(--Neutral-800, #191D23);
                background: var(--Neutral-800, #191D23);
            }
            .profile-section {
                .picture img {
                    width: 4.57143rem;
                    height: 4.57143rem;
                    border-radius: 50%;
                    border: 1px solid var(--cu-black-100);
                    @media screen and (max-width: 550px){ 
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                }
                .creator-name {
                    @media screen and (max-width: 550px){ 
                        font-size: 0.75rem;
                    }                    
                }
            }
            .details-section {
                padding: 0.42857rem 0rem;
                justify-content: start;
                @media screen and (max-width: 550px){ 
                    justify-content: start;
                }
                .details-item {
                    display: flex;
                    justify-content: center;
                    gap: 0.3rem;
                    font-size: 0.85rem;
                    padding: 0 0.5rem;
                    @media screen and (max-width: 550px){ 
                        font-size: 0.675rem;
                    } 
                    .item-name {
                        color: var(--cu-grey-650);
                    }
                }
                .border-right {
                    border-right-width: 1px;
                    border-right-color: var(--cu-black-100)
                }
            }
        }
    }
    .feature-collection-container {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: max-content;
        @media screen and (max-width: 550px){ 
            grid-template-columns: repeat(2, 1fr);
        } 
        .collection-detail {

            .round-img img {
                border-radius: 1.72rem;
                width: 1.72rem;
                height: 1.72rem;                
            }
        }
    }
    .statistics-container {
        width: 100%;
        border-top: 1px solid var(--cu-black-100);
        border-bottom: 1px solid var(--cu-black-100);
        padding: 1rem 0;
        margin: 1rem 0;
        justify-content: space-evenly;
        @media screen and (max-width: 550px){ 
           display: grid;
           grid-template-columns: repeat(2, 1fr);
        }
        .statistics-data {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            .data-name {
                color: var(--neutral-400, #A0ABBB);
                font-size: 1.125rem;
                font-weight: 500;
                letter-spacing: 0.36px;
                @media screen and (max-width: 550px){ 
                    font-size: 0.85rem;
                }
            }
            .data-count {
                color: #FFF;
                font-size: 1.25rem;
                font-weight: 500;
                letter-spacing: 0.4px;
                @media screen and (max-width: 550px){ 
                    font-size: 1rem;
                }
            }
        }
    }
    .announcement-container {
        .announcement-card {
            display: flex;
            border: 1px solid var(--cu-black-400);
            align-items: center;
            padding: 0.29rem 0.58rem;
            gap: 0.71rem;
            border-radius: 0.42rem;
            @media screen and (max-width: 550px){ 
                flex-direction: column;
                align-items: start;
            }
            .time-title-wrapper {
                align-items: center;
                margin-right: 0.5rem;
            }
            .date {
                font-size: 0.625rem;
                color: var(--cu-white-700);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 0.0625rem;
                min-width: 4rem;
            }
            .datetitles-text, .brief-desc {
                font-weight: 700;
                color: var(--cu-white-700);
                @media screen and (max-width: 550px){ 
                    font-weight: 400;
                    font-size: 0.71429rem;
                }
            }
            .brief-desc {
                @media screen and (max-width: 550px){ 
                    color: var(--cu-black-5);
                    text-align: left;
                    word-break: break-all;
                }
            }
            .pro-img {
                width: 1.875rem;
                height: 1.875rem;
                border-radius: 50%;
                border: 1px solid var(--cu-black-100);
                overflow: hidden;
                flex: 0 0 1.875rem;
            }
        }
        .accord-desciption .banner-img {
            @media screen and (max-width: 550px){  
                max-height: unset;
            }
        }
    }
    .event-container {
        display: grid;
        width: 100%;
        @media screen and (max-width: 550px){ 
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
        }
        .event-card {
            // padding: 1rem;
            border-radius: 0.28571rem;
            border: 1px solid var(--cu-black-400);
            width: 100%;
            @media screen and (max-width: 550px){ 
                padding: 0.4rem;
                font-size: 0.625rem;
            }          
            .head {
                .time {
                    color: var(--cu-black-5);
                    font-size: 0.75rem;
                    @media screen and (max-width: 550px){ 
                        font-size: 0.625rem;
                    }
                }
                .title {
                    color: var(--cu-white-200);
                    @media screen and (max-width: 550px){ 
                        font-size: 0.625rem;
                    }
                }
                @media screen and (max-width: 550px){ 
                    flex-direction: column;
                    align-items: start;
                }
            }
            .body {
                height: 8rem;
                background-repeat: no-repeat;
                background-size: cover;
                @media screen and (max-width: 550px){ 
                    height: 5.375rem;
                }  
            }
            .foot {
                color: var(--cu-white-200);
                @media screen and (max-width: 550px){ 
                    font-size: 0.75rem;
                }
            }
        }
    }
}

//this style uses in many places.
.home-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content;
    row-gap: 1rem;
    margin-top: 1rem;
    .info-cat-wrp .info .image-container {
        border: unset;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .screenshot-image {
        border-radius: 0px;
    }
    @media screen and (max-width: 550px){
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        margin-top: 0.5rem;
    }
}

.overflow-lg-auto{
    overflow: auto;
    @media screen and (max-width: 550px){
        overflow: initial;
    }
}
.card-profile-icon {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    border: 1px solid var(--cu-black-100);
    overflow: hidden;
    flex: 0 0 1.875rem;
    @media screen and (max-width: 550px){
        width: 1.25rem;
        height: 1.25rem;
        flex: 0 0 1.25rem;
    }
}