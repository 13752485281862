.profile_view_wrapper-container {

  .profile_view-tabs {
    .manage-btn-wrapper-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30dvh;

      .manage_btn_inline-box {
        width: 40%;
        display: flex;
        justify-content: center;
        border-radius: 12px;
        background-color: var(--cu-black-500);
        border: 1px solid var(--cu-black-100);
        color: var(--cu-white-550);
        padding: 2rem;
        gap: 0.3rem;
        font-size: 1rem;

        button {
          transition: all 0.4s;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .profile_view-gallery-tab {

      .profile_view-manage-btn-box {
        display: flex;
        justify-content: end;
        padding: 1rem 1rem 0 0;
        .profile_view-manage-btn {
          padding: 0.65rem 1rem;
          border-radius: 8px;
          font-size: 0.8rem;
        }
      }
    }
  }

  .tab-container {
    @media screen and (max-width: 550px) {
      padding: 0rem;
      margin: 0rem 1rem;
      border-bottom: 1px solid var(--cu-black-100);

      .tab-list {
        padding: 0rem;
        height: 30px;
        overflow-x: auto;
        box-sizing: border-box;
        width: calc(100% - 0.1875rem);
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .tab-data {
          width: auto;
          font-size: 0.75rem;
          font-weight: 400;
          letter-spacing: 0.03429rem;
          position: relative;
          display: flex;
          gap: 0.25rem;
        }
      }
    }

  }
}