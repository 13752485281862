.collection-terms-container {
  &.terms-mob {
    .collection-terms-table {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      .panel-accord{
background: transparent;
padding: 0.75rem 0rem 0rem 0rem;
      }
      .round label{
        top: 0.25rem;
      }
      .border-line {
        height: 1px;
        width: 100%;
        background: var(--cu-white-100);
      }
      .license-name {
        font-size: 0.75rem;
        color: var(--cu-white-100);
        font-weight: 400;
      }
      .licenses-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0rem 1rem;
        .licenses {
          font-size: 0.75rem;
          font-weight: 400;
          color: var(--cu-black-10);
        }

      }
    }
    .text-body{
        span{
            color: var(--cu-white-100);
        }
    }
  }
}
