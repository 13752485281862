.content-overlay {                    
    background: var(--cu-black-trans-600);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
    z-index: 2;             
    
    .content-details {                    
        opacity: 0;        

        .message {              
            white-space: pre-wrap;
        }

        .center-icon {
            top: 50%;
            left: 50%;            
            transform: translate(-50%, -50%);
            position: absolute;              
        }

        .top-right-icon {
            top: 0.5em;
            right: 0.5em;                        
            position: absolute;
            cursor: pointer;
            
        }

        .readbookicon {
            width:3em;
        }

        .infoicon {
            width:1.5em;
        }
    }
}

.content-overlay:hover {
    opacity: 1;
    cursor: pointer;    
    .content-details {
        opacity: 1;        
    }
}

.content-overlay.nobook:hover {
    cursor: initial;
}





