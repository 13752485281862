.selected-popup {
    background-color: var(--cu-black-500);
    left: 0.7rem;
    bottom: 0px;
    right: 1.2rem;
    position: absolute;
    border-radius: 1.07rem 1.07rem 0px 0px;
    max-height: 9.25rem;
    box-shadow: inset 0.14rem 0.14rem 0.14rem var(--cu-black-900);
    transition: .5s ease;
    z-index: 12;



    @media screen and (max-width: 1024px) {
        width: calc(100vw - 13.5rem);
    }

    @media screen and (max-width: 550px) {
        display: none;
    }

    .custom-button {
        width: 5rem;
        border-radius: 0.75rem;
        border: 0.5px solid var(--Dark-Mode-700, #24272F);
        background: var(--Neutral-900, #0D0F11);
        padding: 0.35rem 0.625rem 0.35rem 0.625rem;
    }
}

.selected-popup-sm {
    background-color: var(--cu-black-500);
    padding: 1rem 1rem;
    margin: 0;
    left: 1rem;
    right: 1rem;
    position: fixed;
    top: 6rem;
    z-index: 3;
    border-radius: 0.125rem;
    @media screen and (max-width: 550px) {
        z-index: 4;
    }
    @media screen and (min-width: 551px) {
        display: none;
    }

    @media screen and (max-width: 550px) {
        font-size: 85%;
        top: 3.8rem;
    }

    .selected-popup-head {
        align-items: center;
        justify-content: space-between;

        .selected-item-head {
            align-items: center;
            gap: 1rem;
        }
    }

    .selected-popup-container {
        overflow: auto;
-webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 13rem);
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        margin-right: 0.5rem;
        align-items: flex-start;
    }

    .nft-gallery-card-image img {
        max-height: 8rem;
    }
}

.selected-popup-scroll {
    max-height: calc(100vh - 23rem);
    overflow: auto;
-webkit-overflow-scrolling: touch;
    padding-bottom: 2rem;
}

@media screen and (max-width: 551px) {
    .view-gal-mob {
        .close {
            opacity: 1;
        }
    }
}

.selected-popup-mob {
    @media screen and (max-width: 551px) {
        .grid {
            padding-right: 0rem !important;
            padding-left: 0rem !important;
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }
    }
}