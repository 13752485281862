.r-multi-dropdown-container  {
    position: relative;
    transition: all 0.4s;
    &:hover {
        .r-multi-dropdown {
            visibility: visible;
            // display: block;
        }
    }
    // .icon {
    //     opacity: 0.6;
    // }
}
.r-multi-dropdown {
    visibility: hidden;
    position: absolute;
    z-index: 4;
    background-color: var(--dark-mode-900);
    border: 1px solid var(--cu-black-100);
    border-radius: 0.25rem;
    transition: all 0.4s;
    cursor: auto;
    padding: 0.5rem 0;
    height: auto;
    max-height: 500px;
    width: 15rem;
    overflow: auto;
    .clear-box {
        text-align: end;
        button {
         color: var(--cu-white-450);
         font-size: 0.75rem;
         padding: 0rem 0.8rem;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;       
        li {
            text-align: start;
            color: var(--cu-white-450);
            font-size: 0.75rem;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
            label {
                cursor: pointer;
                padding: 0 0 0 1rem;
                margin: 0 0 0.375rem 0;
            }
        }
    }
    .ul-2 {
        padding: 0.5rem 0;
        button{
            padding: 0 0 0 1rem;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            gap: 0.8rem;
            width: 100%;
        }
    }
}