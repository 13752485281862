.lg-card {
    grid-template-columns: repeat(4, 1fr);
}

.lg-asset-card {
    grid-template-columns: repeat(4, 1fr);
}

.sm-card {
    grid-template-columns: repeat(5, 1fr);

    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2 1fr) !important;
    }
}

.nft-collections {
    display: flex;
    flex-direction: column;
    height: 100%;

    .overlay {
        display: none;
    }

    .page-sm-tabs {
        display: none;
    }

    .my-collections {
        padding: 1rem 0 0 1rem;
        background: var(--dark-mode-900);
        box-shadow: inset 0.125rem 0.125rem .25rem var(--cu-black-900);
        height: 100%;
        display: flex;
        gap: 1.25rem;
        position: relative;
        height: calc(100% - 1rem);
.collection-box-img{
    &:hover{
      .nft-img{
        .hover-overlay {
            // background: rgb(15 15 15 / 50%);
            width: 100%;
            height: 100%;
            border-radius: 0.28rem;
        }
      }  
    }
}
        .nft-frame-viewer-collection {
            display: inline-flex;
            height: 38vh;
            width: 100%;
            object-fit: cover;

            @media screen and (max-width: 550px) {
                height: 42vh;
            }
        }
    .side-scroller{
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            width: 0.1875rem;
    }
}
        .nft-frame-viewer-collection-zoom {
            display: inline-flex;
            height: 100%;
            min-height: 100%;
        }

        .nft-display-panel {
            height: 100%;
            flex: 1;
            padding-bottom: 0rem;
            &.list {
                overflow-y: unset !important;
            }

            @media screen and (max-width: 550px) {
                overflow: unset;
                // padding-top: 1rem;
                overflow-y: auto;
                padding-bottom: 3rem;
            }

            .panel-area {
                row-gap: 1rem;
                padding-bottom: 1rem;
                // padding: 1rem 0;
                .card-area {
                    display: flex;
                }
            }
        }

        .nft-display {
            /* Dark Mode / 300 */
            display: flex;
            flex-direction: column;
            gap: 0.465rem;
            width: 100%;
            .collection-image {
                border: 1px solid #20232A;
            }

            &.selected {
                .collection-image {
                    // border-image: linear-gradient(#00C5FF, #4745ED) 20;
                    // border-width: 1px;
                    position: relative;
                    border: unset;
                    border: 1px solid var(--cu-blue-70);

                    //border: 1px solid var(--cu-blue-70);
                }
            }

            .tool-box {
                display: flex;
                // align-items: flex-end;
                z-index: 2;
                

                img {
                    width: 1rem;
                    height: .8025rem;
                    cursor: pointer;
                }
            }

            .nft-name,
            .nft-price,
            .nft-type {
                font-size: .5rem;
                text-align: left;
                line-height: .75rem;
                .nft-profile-img{
                    aspect-ratio: 1;
                }
            }


            .nft-name {
                color: #666AFF;

                span {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }

            .nft-img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                margin-right: auto;
                margin-left: auto;
                border-radius: 0.28rem;

                &:hover {
                    .hover-overlay {
                        // background: rgb(15 15 15 / 50%);
                        width: 100%;
                        height: 100%;
                        border-radius: 0.28rem;
                    }
                }

                .collection-image {
                    border-radius: 0.28rem;
                }
            }

            .nft-name-gallery {
                font-size: 0.875rem;
                line-height: normal;
                letter-spacing: 0.02rem;
                word-break: break-word;
            }

            .nft-price {
            
                display: flex;
                border-radius: 0.25rem;
                line-height: 0.9rem;
                span {
                    font-size: .75rem;
                    background: -webkit-linear-gradient( #A0ABBB,#A0ABBB);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 500;
                }
            }

            &.nft-display-lg {

                .nft-img {
                    height: 100%;
                    width: 100%;
                }
            }

            &.skelton-loading {
                .nft-img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .nft-display-table {
            font-size: .75rem;
            font-weight: 600;

            .arrow-flipped {
                transform: scaleY(-1);
                -moz-transform: scaleY(-1);
                -webkit-transform: scaleY(-1);
                -ms-transform: scaleY(-1);
            }

            .nft-img {
                height: 2rem;
                width: 2rem;
                cursor: pointer;
            }

            tbody {
                tr {
                    border-bottom: 0.5px solid var(--cu-black-trans-250)
                }

                td {
                    img {
                        width: 4.063rem;
                        height: 2.93rem;
                    }
                }
            }
        }

        .nft-details {
            height: 100%;
            margin-left: auto;
            /* Dark Mode / 300 */
            border-radius: 4px;

            .nft-panel {
                width: 32rem;
                padding-bottom:1.25rem;
                background: var(--cu-black-500);
                border: 1px solid var(--cu-black-400);
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: .625rem;
                overflow: auto;
-webkit-overflow-scrolling: touch;
                scroll-behavior: smooth;
                border-bottom: 0;
                z-index: 2;
                position: relative;
                border-radius: .625rem;
                &:hover {
                    .tool-bar {
                        display: flex;
                    }
                }

                @media screen and (max-width: 550px) {
                   // padding: 0.625rem;
                    height: 100vh;
                    border:none;
                }

                &.zoom-panel {
                    /* when modal active */
                    touch-action: none;
                    -webkit-overflow-scrolling: none;
                    overflow: hidden;
                    /* Other browsers */
                    overscroll-behavior: none;
                    position: fixed;
                    z-index: 12;
                    right: 0;
                    left: 0;
                    top: 0rem;
                    bottom: 1rem;
                    width: 100% !important;
                    background-color: var(--cu-black-900);
                }

                &::-webkit-scrollbar {
                    display: none;
                }

                .tool-bar {
                    display: none;
                    align-items: center;
                    gap: 0.625rem;
                    margin-top: 0.4375rem;
                    margin-left: auto;
                    position: absolute;
                    z-index: 14;
                    top: 0.4375rem;
                    right: 0.6rem;

                    @media screen and (max-width: 550px) {
                        right: 1rem;
                    }

                    .zoom {
                        width: 1.75rem;
                        height: 1.75rem;
                        display: inline-block;
                        @media screen and (max-width: 550px) {
                        &.mob-hide{
                            display: none;
                        }
                    }
                    }

                    .close {
                        width: 1.5rem;
                        height: 1.5rem;
                    }

                    .close,
                    .zoom {
                        cursor: pointer;
                    }
                }

                .nft-img {
                    width: 100%;
                    margin: 0 auto;
                    background-color: var(--cu-black-700);
                    padding: 1rem;
                    height: 37vh;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 0rem 1.25rem 0rem 1.25rem;
                    @media screen and (max-width: 550px) {
                    padding: 0rem 0.825rem 0rem 0.825rem;
                    }

                    .refresh-icon {
                        margin-left:0.3em;
                    }
                    
                    .tab-box {
                        .fav-box {
                            margin-left: auto;
                            cursor: pointer;
                        }

                    }
                    .border-0{
                        border:none !important;
                    }
                    .details-box {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 1rem 0 2.5rem;
                        gap: .75rem;
                        font-size: .75rem;
                        color: var(--cu-white-900);
                        border-top: 1px solid var(--cu-black-100);
                        overflow: auto;
-webkit-overflow-scrolling: touch;
                        &.pb-none{
                            padding-bottom: 0rem;
                        }

                        @media screen and (max-width: 550px) {
                            border: 0px;
                            padding: .625rem 0rem 1.875rem;
                            border-top: 1px solid var(--cu-black-100);
                        }

                        img {
                            width: 1rem;
                        }

                        .shapes-img {
                            width: 1.5rem;
                            height: 1.5rem;
                            margin: auto;
                            left: -0.1rem;
                            position: relative;
                        }

                        .details-sm {
                            font-size: 1rem;

                            ul.details-title-sm {
                                display: flex;
                                gap: .25rem;
                                list-style-type: none;
                                font-size: 1rem;
                                li {
                                    color: var(--cu-white-200);
                                    font-weight: 600;
                                    font-size: 1.125rem;

                                    &::marker {
                                        margin: 3rem;
                                    }
                                }
                            }
                        }

                        .details-container {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .margin-0-desc{
                            margin-top: -1.25rem;
                            @media screen and (max-width: 550px) { 
                                margin-bottom: 1rem;
                            }
                        }
                        .data-box {
                            display: flex;
                            flex-direction: column;
                            border: 0.5px solid #30333a;
                            border-top: none;
                            border-left: none;
                            border-bottom: none;
                            padding: 0rem 1rem 0rem 0rem;
                            margin-right: 0.75rem;
                            margin-bottom: 1.375rem;
                            .data-box-id{
                                border-right: none;
                            }

                            .data-title {
                                text-align: left;
                                font-weight: 400;
                                font-size: 0.75rem;
                                color: var(--cu-black-10);
                            }

                            .data-value {
                                flex: 1;
                                word-break: break-word;
                                text-align: left;
                                font-weight: 400;
                                color: #F7F8F9;
                                font-size: 0.75rem;
                                align-items: center;
                                text-decoration: none;
                            }
                        }

                        .data-box-one {
                           // flex: 1 1 10rem;
                        }

                        .details-description {
                            width: 100%;
                            text-align: left;
                            color: #F7F8F9;
                            font-size: 0.875rem;
                            white-space: pre-wrap;
                            word-break: break-all;
                            height: auto;
                        }

                    }

                    .open-btn {
                        position: absolute;
                        bottom: 0.6rem;
                        left: 1rem;
                        right: 1rem;
                        padding-right: 0.625rem;
                    }

                    .details-meta-user {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding-top: .75rem;
                        border: 0.5px solid #30333a;
                        border-bottom: none;
                        border-left: none;
                        border-right: none;
                        .details-meta-user-header {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            padding-bottom: 0.625rem;

                            .user-profile {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                img {
                                    width: 2rem;
                                    margin: 0 1rem 0 0rem;
                                    height: 2rem;
                                    border-radius: 50%;
                                }

                                .user-name {
                                    color: var(--cu-white-200);
                                    font-size: 1.125rem;
                                    font-weight: 600;
                                }
                            }

                            .social-icons {
                                display: flex;
                            }
                        }

                        .details-meta-user-description {
                            display: flex;
                            color: var(--cu-white-200);
                            font-size: 0.875rem;
                            white-space: pre-wrap;
                            text-align: left;
                            margin-top:0.5rem;
                        }
                    }

                    .event-container {
                        border-radius: 6px;
                        background: var(--cu-black-650);
                        display: flex;

                        .event-date {
                            width: 10rem;
                            padding: 0.5rem 0rem;
                            color: var(--cu-black-5);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            // font-size: 0.6rem;
                            // letter-spacing: 0.2rem;
                            p {
                                margin: 0.5rem 0;

                            }

                            .event-time {
                                font-size: 0.6rem;
                            }
                        }

                        .event-content {
                            align-items: left;
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            min-width: 19rem;
                            border-radius: 0.5rem;
                            text-align: left;
                            justify-content: center;
                            padding: 0.5rem;
                            background: var(--cu-black-500);
                            border: 2px solid var(--cu-black-650);

                            p {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                overflow: hidden;
                            }

                            .event-title {
                                font-size: 1rem;
                                margin: 0.25rem 0;
                            }
                        }

                        .event-text {
                            flex-direction: column;
                            justify-content: center;
                            align-items: unset;
                            text-align: left;

                        }
                    }

                    .view-more {
                        display: flex;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        color: var(--cu-black-5);
                        font-size: 12px;
                        cursor: pointer;

                    }
                }

            }

            &.zoom-nft {
                width: calc(100% - 2.5rem);
                overflow-y: scroll;
                position: absolute;
                right: 1.8rem;
                top: .8rem;

                .nft-img {
                    display: none;
                }

                .nft-zoom-img {
                    width: 100%;
                    height: 100%;
                    zoom: 1.5;

                    img {
                        max-height: 45vh;
                    }
                }

                .nft-panel {
                    width: 100%;

                }


                .details {
                    display: none;
                }
            }

        }

        @media screen and (max-width: 1025px) {
            .nft-details {
                .nft-panel {
                    .details {
                        .open-btn {
                            display: block;
                            left: 0rem;
                            right: 0rem;
                            background: var(--cu-black-500);
                            padding: 1rem;
                            bottom: 0;

                            @media screen and (max-width: 550px) {
                                position: fixed;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {

            height: calc(100% - 6.4375rem);

            .nft-details {
                position: fixed;
                right: 0;
                height: 100vh;
                top: 1rem;
                left: 0rem;
                z-index: 12;

                &.zoom-nft {
                    width: 92%;
                }

                .nft-panel {
                    width: 100%;

                }
            }
        }

        @media screen and (max-width: 602px) {
            height: calc(100% - 8.4375rem);

            .nft-details {
                .nft-panel {
                    width: 100%;

                    .nft-img {
                        width: 100%;
                    }
                }
            }
        }

        @media screen and (max-width: 550px) {
            height: 100%;
            padding: 1rem;
            justify-content: center;
            padding-top: 3rem;
            gap: 1.25rem;
            .panel-area {
                margin: auto;
                place-content: center;

                &.nft-display-lg {
                    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
                }

                &.nft-display-lg-asset {
                    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
                    padding-bottom: 1rem;
                }

                &.nft-display-lg-asset-sm {
                    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
                }

                &.nft-display-list {
                    display: block;
                }
            }

            .nft-display {
                &.nft-display-lg {

                    .nft-img {
                       // height: 10.5rem;
                    }
                }

                .tool-box {
                    .fav {
                        display: none;
                    }
                }
            }

            .nft-details {
                right: 0rem;
                top: 0rem;

                .nft-panel {
                    .details {
                        .details-box {
                            gap: .625rem;
                            overflow: auto;
-webkit-overflow-scrolling: touch;
                            // max-height: calc(100vh - 26rem) !important;
                            padding-bottom: 2rem;
                            @media screen and (max-width: 550px) {
                                padding-bottom: 7rem;
                            }
                            .tab-box {
                                width: 100%;

                                .tab-sm {
                                    width: 100%;
                                }
                            }

                            .data-box .data-title {
                                flex-basis: 2rem;
                            }

                            .data-box {
                                //flex: 1 1 10rem;
                            }
                        }
                    }

                }

                &.zoom-nft {
                    width: auto;
                    top: 2.5rem;
                    right: .8rem;

                    .nft-panel {
                        width: 95vw;
                    }
                }

            }

            .nft-display-table {
                thead {
                    th {
                        .functional {
                            width: max-content;
                            padding: 3px 5px;
                            border: 1px solid #FFFFFF;
                            border-radius: 4px;
                        }
                    }
                }

                tbody {
                    td {
                        img {
                            width: 5.58312rem;
                            height: 2.93rem;
                        }

                    }

                }
            }

        }
        // &::-webkit-scrollbar-track:vertical {
        //     margin-top: 5rem;
        // }
    }

    @media screen and (max-width: 550px) {


        .baner-lg-open {

            display: flex;
            position: fixed;
            top: 5.1875rem;
            z-index: 10;
            right: 15px;
            min-width: 12rem;

            .overlay {
                display: block;
                position: absolute;
                width: 100%;
                height: calc(100vh - 6.66rem);
                z-index: -1;
            }
        }

        .tabs {
            display: flex;
            align-items: center;
            gap: .625rem;
            flex: 1;
        }

        .page-sm-tabs {
            position: fixed;
            top: 3.125rem;
            width: 100%;
            display: flex;
            padding: 0.3rem 1rem 0.3rem 0.625rem;
            align-items: center;
            background: var(--cu-black-500);
            z-index: 4;
        }
    }

    .list-wid {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.details-container {
    flex-wrap: wrap;
}

.head-tabs {
    color: var(--cu-white-100) !important;
    font-size: 0.875rem !important;
    background: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    width: 100% !important;
}

.zoom-close {
    position: absolute !important;
    right: 2rem;
}

.zoom {
    display: none;

    @media screen and (max-width: 550px) {
        display: unset;
    }
}

.btn-mob {
    display: none;

    @media screen and (max-width: 550px) {
        display: unset;
        margin-bottom: 0rem;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: var(--cu-black-500);
        padding: 0.6rem;
    }
}
.empty-page {
    span {
        color: var(--cu-white-200);
    }
}

.treemap {
    margin-top: -2em;        
}