.tab-container {
    padding: 1rem 1rem 0rem 1rem;
    background: var(--neutral-900);
    .tab-data {
        cursor: pointer;
        color: var(--cu-black-10);
        font-size: .875rem;
        line-height: 1.21429rem;
        padding-bottom: 0.5rem;
        display: flex;
        gap: 0.25rem;
        position: relative;
        &.not-active{
            &::after{
              background: none repeat scroll 0 0 transparent;
              bottom: 0;
              content: "";
              display: block;
              height: 1.5px;
              left: 50%;
              position: absolute;
              background: var(--cu-white-200);
              transition: width 0.3s ease 0s, left 0.3s ease 0s;
              width: 0;
            }
            &:hover{
              color: var(--cu-white-200);
              &::after { 
              width: 100%; 
              left: 0; 
            }}
          }
        &.active {            
            color: var(--cu-white-200);
            border-color: var(--cu-white-200);
            border-bottom-width: 2px;
            @media screen and (max-width: 550px) {
                border-bottom-width: 1.6px;
                border-color: var(--cu-white-550);
            }
        }
        &.hidden {
            display: none;
        }
    }
}
.details-container {
    .preview-card-image {
        padding: 0;
        @media screen and (max-width: 550px) {
        overflow: auto;
-webkit-overflow-scrolling: touch;
        padding: 0rem 0rem 12rem 0rem;
        }
    }
    .user-preview{
        max-height: 100%;
        @media screen and (max-width: 550px) {
        padding: 0rem;
        }
    }
}

.discover-collector-details{
    .home-grid{
        margin-top: 0rem;
    }
    .details-container{
        @media screen and (max-width: 550px) {
            .slider-user{
                // height: calc(100vh - 9rem);
            }
        }  
    }
}
.discover-collectors{
    .home-grid{
        @media screen and (max-width: 550px) {
        margin-top: 0rem;
        overflow: auto;
-webkit-overflow-scrolling: touch;
        &.skelton-wrap{
            margin-top: 4rem; 
        }
        }
    }
}


.tab-container-gallery {
    padding: 0 1rem 0rem 1rem;
    background: var(--neutral-900);
    .tab-data-gallery {
      border-bottom: solid 1px var(--cu-black-10);;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--cu-black-10);
      font-size: .875rem;
      line-height: 1.21429rem;

      .tab{
        font-size: 0.875rem;
      line-height: 1.21429rem;
      padding-bottom:  .625rem;
      box-sizing: border-box;
      &.not-active{
        &::after{
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          background: var(--cu-white-200);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover{
          color: var(--cu-white-200);
          &::after { 
          width: 100%; 
          left: 0; 
        }}
      }
      &.active {
        color: var(--cu-white-200);
        border-bottom: solid 1px var(--cu-white-200);

      }
      }

    }
  }