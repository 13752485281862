.top-panel-container {
    background: var(--neutral-900);
    .top-banner {
        width: 100%;
        height: 20rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        img {
            width: 100%;    
            height: 100%;
            object-fit: cover;
        }
        &.no-icon {
            background: var(--cu-black-900);
            img {
                object-fit: fill;
            }
        }
        .profile-icon {
            width: 6.57143rem;
            height: 6.57143rem;
            top: 40%;
            left: 1.15rem;
            background: var(--cu-black-500);
            border-radius: 6.57143rem;
            background-size: 100% 100%;
            border: 1px solid var(--cu-black-100);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            overflow-clip-margin: unset;
            &.no-icon {
                background-size: contain;
                background-position: center;
                background-color: var(--cu-black-900);
            }
        }
        .fix_pixelated-image {
            width: 6.57143rem;
            height: 6.57143rem;
            top: 40%;
            left: 1.15rem;
            border-radius: 6.57143rem;
            overflow-clip-margin: unset;
            overflow: visible;
            object-fit: cover;
            border: 1px solid var(--cu-black-100);
            overflow: hidden;
        }

    }
    .profile-details {
        padding: 0 2.28571rem 1.14286rem 1.14286rem;
        .profile-name {
            span {
                color: var(--cu-white-200);
                font-size: 1.125rem;
                line-height: 1.0625rem;
            }
        }
        .profile-social-icon {
            img {
                width: 1.71429rem;
                height: 1.71429rem;
            }
        }
        .description {
            text-align: left;
            word-break: break-word;
            color: var(--cu-white-700);
            font-size: .875rem;
            font-weight: 400;
            line-height: 1.1875rem;

            span {
                color: var(--cu-white-700);
                font-size: .875rem;
                font-weight: 400;
                line-height: 1.1875rem;
            }
        }
        .follow-btn-container {
            border-radius: 0.42857rem;
            border: 1px solid var(--cu-black-100);
            font-size: 1rem;;
            line-height: 1.21429rem; 
            padding: 0.57143rem 1rem;
            cursor: pointer;
            color: var(--cu-white-100);
            transition: all 0.3s;
            &:hover {
                color: var(--cu-white-900);
                background: var(--dark-mode-300);
            }
        }
    }
    .view-less {
        cursor: pointer;
        padding: 0 !important;
        font-style: italic;
    }

    .view-more {
        transform: rotate(180deg);
        padding: 0 !important;
        cursor: pointer;
    }
}