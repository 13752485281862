.transaction-summary {
    background: var(--cu-black-700);
    box-shadow: inset 2px 2px 4px #000000;
    height: 100%;
    width: 100%;

    .baner {
        .back-btn {
            margin-left: auto;

            button {
                padding: .625rem;
                width: 100%;
                background-color: var(--cu-black-500);
                border-radius: .6875rem;
                font-size: .875rem;
                line-height: 1.0625rem;
            }
        }
    }

    .baner-sm {
        display: none;

        .back-btn {
            margin-left: auto;

            button {
                padding: .3125rem .625rem;
                width: 100%;
                background-color: var(--cu-black-500);
                border-radius: .6875rem;
                font-size: .625rem;
            }
        }
    }

    .contents {
        display: flex;
        gap: .625rem;
        flex-direction: column;
        background: #0D0F11;
        box-shadow: inset 2px 2px 4px #000000;
        padding: 1rem 2rem 1rem 1rem;
        width: 100%;
        overflow-y: auto;
        scroll-behavior: smooth;
        height: calc(100% - 5rem);
        .frame-details {
            padding: .25rem 0 .4375rem 0;
            width: 100%;

            p {
                font-size: .625rem;
            }

            .frame-box {
                width: 90%;
                margin: auto;
                padding: 0.25rem;
            }
        }

        .frame-data-panel {
            flex: 1 1 32.188rem;

            .frame-data {
                padding-top: .53125rem;

                .frame-title {
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1.5rem;
                    color: var(--cu-white-700);
                }

                .frame-data-box {
                    padding: .875rem 0 .625rem 0;
                    font-size: .875rem;
                    display: flex;
                    flex-direction: column;
                    gap: .625rem;

                    ul {
                        list-style: disc;
                        list-style-position: inside;
                    }
                }

                .data-list-box {
                    display: flex;
                    text-align: left;

                    .data-title {
                        flex: 1 1 8rem;
                        white-space: nowrap;
                    }

                    .data-value {
                        word-break: break-word;
                        flex: 1 1 88.6%;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .data-list-box {
            display: flex;
            text-align: left;

            .data-title {
                color: var(--cu-grey-600);
            }

            .data-value {
                color: var(--cu-white-700);
            }

            .data-value-btn-txt {
                // font: bold 20px 'Quicksand', san-serif;
                color: var(--cu-white-700);
                transition: all 0.2s;
                text-transform: capitalize;
            }

            .waiting::after {
                content: "";
                position: absolute;
                width: 1rem;
                height: 1rem;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 0.2rem solid transparent;
                border-top-color: var(--cu-white-900);
                border-radius: 50%;
                animation: button-loading-spinner 1s ease infinite;
            }

            @keyframes button-loading-spinner {
                from {
                    transform: rotate(0turn);
                }

                to {
                    transform: rotate(1turn);
                }
            }
        }

        .instruct-panel {
            display: flex;
            // flex-direction: column;
            gap: .625rem;
            flex: 1 1 30.875rem;

            .panel {
                flex: 1;
            }
        }

        @media screen and (max-width: 1025px) {
            .instruct-panel {
                .panel {
                    flex-basis: 100%;
                }
            }
        }

        .qr-box {
            font-size: .875rem;
            color: var(--cu-white-700);
            padding: .625rem;
            text-align: left;

            .box {
                padding: .625rem;
                border: .0625rem solid var(--cu-black-100);
                border-radius: .625rem;
                margin-top: .625rem;

                .qr-desc {
                    cursor: pointer;
                    word-break: break-word;
                }
            }

            .title {
                color: var(--cu-grey-600);
            }
        }

    }

    @media screen and (max-width: 550px) {
        .contents {
            padding: 1rem;

            .frame-data-panel {
                .frame-data {
                    padding-top: .53125rem;

                    .price-btn {
                        width: 100%;
                    }
                }
            }
        }

        .baner {
            display: none;
        }

        .baner-sm {
            display: block;
        }

        .instruct-panel {
            flex-direction: column;
        }
    }
}