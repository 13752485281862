    .overflow-auto {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
.primary{
    position: absolute;
    top: 0.5625rem;
    background: var(--cu-black-500);
    left: 0.5rem;
    padding: 0.1875rem 0.375rem;
    font-size: 0.5rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid transparent;
    &:hover{
        border: 0.0625rem solid var(--cu-blue-70);
    }

}
.no-info{
    height: 100% !important;
}
.small-card{
    .image-container {
        border: 0.0625rem solid var(--cu-black-900);
        overflow: hidden;
        height: 13rem;
        @media screen and (max-width:550px) {
            height: 10rem !important;
        }
    }
}
.info-cat-wrp {
    border-radius: 0.4rem;
    // background-color: var(--cu-black-500);
    // border: 0.0625rem solid var(--cu-black-100);
    // min-height: 10rem;
   
    
    @media screen and (max-width:550px) {
        height: fit-content;
        min-width: 75vw;
        
    }

    .info {

        .image-container:before {
            -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
            background: transparent;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            z-index: 2;
        }
      

        .image-container {
            border: 1px solid var(--Dark-Mode-300, #30333A) !important;
            overflow: hidden;
            aspect-ratio: 16/9;
            border-radius: 0.25rem !important;
            @media screen and (max-width:550px) {
                // height: 10rem;
            }
            .image-cls {
                background-size: cover;
                width: 100%;
                aspect-ratio: 16/9;
                background-repeat: no-repeat;
                background-position: center;
                padding: 1rem;
            }

            &:hover {
                .content-overlay {
                    opacity: 1;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%) !important;
                    backdrop-filter: blur(1.5px);
                }

                .content-details {
                    top: 50%;
                    left: 50%;
                    opacity: 1;
                }
                & + div .edit{
                    color: var(--cu-white-200);
                }
            }

            .content-overlay {
                background: var(--cu-black-trans-600);
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                -webkit-transition: all 0.4s ease-in-out 0s;
                -moz-transition: all 0.4s ease-in-out 0s;
                transition: all 0.4s ease-in-out 0s;
                z-index: 1;
            }

            .content-details {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                justify-content: center;
                align-items: center;
                position: absolute;
                text-align: center;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                width: 100%;
                top: 50%;
                left: 50%;
                opacity: 0;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                -webkit-transition: all 0.3s ease-in-out 0s;
                -moz-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
                z-index: 2;
                .content-wrapper{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .set-primary{
                        position: absolute;
                        top: 0.5625rem;
                        background: var(--Neutral-900, #0D0F11);
                        border: 1px solid var(--Dark-Mode-300, #30333A);
                        left: 0;
                        padding: 0.25rem 0.625rem;
                        font-size: 0.5rem;
                        border-radius: 0.25rem;
                        &:hover{
                            background:#191D23 ;
                        }
                    
                    }
                }
            }
        }
        

        .info-title {
            font-size: 0.875rem;
            color: var(--cu-white-900);
            @media screen and (max-width:550px) {
                font-size: 0.625rem;
            }
            .primary-gallery{
                border-radius: 0.5rem;
                background: var(--cu-black-900);
                color:var(--neutral-500, #64748B);
                font-size:0.75rem;
                display: flex;
                padding: .25rem .5rem;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border: 1px solid var(--neutral-500, #64748B);

            }
            .card-profile-icon {
                width: 1.875rem;
                height: 1.875rem;
                border-radius: 50%;
                border: 1px solid var(--cu-black-100);
                overflow: hidden;
                flex: 0 0 1.875rem;
                @media screen and (max-width:550px) {
                    width: 1.25rem;
                    height: 1.25rem;
                    flex: 0 0 1.25rem;
                }

            }
        }

        .info-subtitle {
            font-size: 0.625rem;
            color: var(--cu-white-100);
            line-height: 0.75rem;
        }

        .nft-icon-cls {
            background-size: 100%;
            width: 1rem;
            min-height: 0.8rem;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url('/Assets/Images/NftImage.svg');
        }

    }

}

.creator-named {
    color: var(--neutral-500, #64748B);
    font-family: ZenKaku;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    @media screen and (max-width: 550px){ 
        font-size: 0.6rem !important;
    }
}
.display-tag{
    border-radius: 0.5rem;
border: 1px solid var(--Dark-Mode-300, #30333A);
font-size: .75rem;
opacity: 0.7;
background: var(--Neutral-900, #0D0F11);
display: flex;
padding: 0.25rem 0.625rem;
flex-direction: column;
justify-content: center;
align-items: flex-end;
gap: 0.25rem;
}