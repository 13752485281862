.new-frames {
  .frame-display.dark {
    background: var(--cu-black-700);
  }

  &.active {
    display: flex;

    .custome-frame-panel {
      order: 0;
      flex-basis: calc(100% - 28rem);
    }
  }

  .custome-frame-panel {
    background: unset;
    border: unset;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .frame-panel-lg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-right: 1rem;
    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    }
  }

  .frame-panel-sm {
    display: grid;
    grid-template-columns:  repeat(5, 1fr);

    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
      .card-colr {
        background: var(--cu-black-500);
        border-radius: 0.25rem;
      }
    }
  }

  .frame-details-container {
    border-radius: 0.25rem;
    padding: 0;
    font-size: 0.625rem;
    line-height: 0.75rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // gap: .25rem;
    // padding-top: .875rem;
    cursor: pointer;
    background-color: var(--neutral-900);
  }
  .frame-display {
    border: 0.5px solid var(--cu-black-100);
    border-radius: 0.25rem;
    padding: 0;
    font-size: 0.625rem;
    line-height: 0.75rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // gap: .25rem;
    // padding-top: .875rem;
    cursor: pointer;
    background-color: var(--neutral-900);

    &.activeCard {
      // border-image: linear-gradient(#00C5FF, #4745ED) 20;
      // border-width: 1px;
      border: 0.0625rem solid transparent;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0.25rem;
        padding: 1px;
        border: 0.0625rem solid var(--cu-black-100);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: 9;
      }
    }

    .zoom-icon {
      display: none;
    }

    &:hover {
      .frame-price {
        background: #24272F;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        .frame-title {
          color: var(--cu-white-200);
        }
      }

      .zoom-icon {
        display: block;
      }
    }

    .frame-nft-img {
      // min-height: 5rem;

      @media screen and (max-width: 550px) {
        padding: 0px 1rem;
      }
    }

    .frame-price {
      border-top: 0.0625rem solid var(--cu-black-100);
      padding: 0.6rem 0;
      color: var(--cu-blue-150);
      background: #14171F;
      // margin-top: 0.5rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      .frame-title {
        color: var(--cu-white-200);
        font-size: .875rem;
        padding-bottom: .25rem;
        font-weight: 400;
      }

      .frame-price-section {
        background-color: var(--cu-black-700);
        margin: 0.375rem 0.375rem 0 0.375rem;
        width: "100%";
        height: 2rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--cu-white-200);
        font-size: 0.875rem;

        :hover {
          background-color: #323A46;
          width: "100%";
          height: 2rem;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--cu-white-200);
          font-size: 0.875rem;
        }
      }
    }
  }

  .frame-details-display {
    border: 0;
    border-radius: 0.25rem;
    font-size: 0.625rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 100%;

    .frame-data {
      max-height: calc(100% - 1rem);
    }

    .frame-details {
      display: flex;
      flex-direction: column;
      padding: 0.625rem 0;
      gap: 0.625rem;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      border-top: 1px solid var(--cu-black-100);
      overflow: show;
      -webkit-overflow-scrolling: touch;
      text-transform: capitalize;
      padding-top: 1.42857rem;

      .nft-frame-viewer {
        height: 100%;
      }

      @media screen and (max-width: 550px) {
        padding: 0.625rem 0rem 0rem 0rem;
      }

      &.active {
        max-height: fit-content;
      }

      .title,
      .data-title {
        font-size: 0.85714rem;
        text-align: left;
        color: var(--cu-black-10);
        font-weight: 400;
        line-height: 1.21429rem;
      }

      .head-title {
        text-align: left;
        flex: 1;
        font-weight: 600;
        font-size: 1.125rem;
        color: var(--cu-white-700);
      }

      .value,
      .data-value {
        font-size: 0.85714rem;
        font-weight: 400;
        word-break: break-word;
        text-align: left;
        color: var(--cu-white-900);
        line-height: 1.21429rem;
      }
      .description-detail {
        display: flex;
        padding: 0.42857rem 0rem;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem 0.57143rem;
        .description-data {
          border-right: 1px solid var(--cu-black-100);
          padding-right: 1rem;
          &:last-child {
            border-right: unset;
          }
        }
      }
    }

    .frame-nft-img {
      background: var(--cu-black-900);
      padding: 0.625rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #20232a;
    }
    .nft-zoom-img {
      height: 90vh;
      max-width: 100vw;
      padding: 5%;
    }
    .price-btn {
      display: flex;
      // padding: 0.42857rem 2.85714rem;
      // align-items: center;
      // gap: 0.71429rem;
      // border-radius: 0.71429rem;
      // border: 1px solid var(--cu-black-70);
      // background: var(--cu-black-700);
      color: var(--neutral-50, #f7f8f9);
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;
      @media screen and (max-width: 550px) {
        padding: 0.625rem 1rem;
        flex: 1;
        font-size: 0.875rem;
        justify-content: center;
      }
      &.buy-btn {
        padding: 0.5rem;
        border-radius: 12px;
        border: 1px solid var(--neutral-400, #a0abbb);
        background: var(--neutral-900, #0d0f11);
        .buy-data {
          border-right: 1px solid var(--cu-black-100);
          color: var(--neutral-50, #f7f8f9);
          font-family: ZenKaku;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:last-child {
            display: flex;
            width: 100%;
            justify-content: center;
            border-right: unset;
          }
        }
        .frame-qty-select {
          div:first-child {
            gap: 0.71429rem;
          }
        }
        .qty-btn {
          min-width: auto;
        }
      }
    }
    .frame-price {
      padding: 0.25rem 0;
      display: flex;
      gap: 1rem;
      min-height: 2rem;
      .price-button {
        border: 0.0625rem solid var(--cu-blue-150);
        border-radius: 0.25rem;
        flex: 1;
        display: flex;
        justify-content: center;
      }

      .frame-qty-select {
        border: 0.0625rem solid var(--cu-blue-150);
        border-radius: 0.25rem;
        min-height: 2rem;
      }

      .frame-qty-select-item {
        max-height: 2rem !important;
      }

      .frame-price-value-sm {
        display: none;
      }
    }
  }

  .details-sm {
    display: none;
  }

  .frameshop-details {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px); /* Apply the blur effect */
    z-index: 1000;

    .details-container {
      display: flex;
      max-height: 95vh;
      border-radius: 0.25rem;
      border: 1px solid var(--dark-mode-300);
      overflow: hidden;
      padding: 2rem;
      gap: 2rem;
      position: absolute;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      top: 2rem;
      border-radius: 20px;
      background: var(--Neutral-900, #0d0f11);
      z-index: 10;
    }
    .body {
      display: flex;
      width: 100%;
      @media screen and (max-width: 550px) {
        height: 5.375rem;
      }
    }
    .head {
      .time {
        color: var(--cu-black-5);
        font-size: 0.75rem;
        @media screen and (max-width: 550px) {
          font-size: 0.625rem;
        }
      }
      .title {
        color: var(--cu-white-200);
        font-size: 1rem;
        text-align: left;
        @media screen and (max-width: 550px) {
          font-size: 0.625rem;
        }
      }
      @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: start;
      }
    }

    &.active {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .tool-box {
        position: absolute;
        top: 0;
        right: 0;
      }

      .frame-nft-img {
        background-color: unset;

        img {
          max-height: unset !important;
        }
      }
    }

    .tool-box {
      display: none;
      gap: 0.5rem;
      justify-content: flex-end;
      padding: 1rem;
      position: absolute;
      right: -5px;
      top: -5px;
      align-items: center;
      z-index: 2;

      @media screen and (max-width: 550px) {
        right: 5px;
        top: 6px;
      }
    }

    &.active {
      position: fixed;
      z-index: 12;
      right: 0;
      left: 0;
      top: 0rem;
      bottom: 0rem;
      width: 100%;
      background: black;
    }
  }

  @media screen and (max-width: 550px) {
    .frame-details {
      max-height: calc(100vh - 21rem) !important;
    }

    .frameshop-details {
      position: fixed;
      z-index: 12;
      left: 0;
      overflow: hidden;
      bottom: 0;
      top: 0;

      @media screen and (max-width: 550px) {
        overscroll-behavior: none;
        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow-y: scroll;
      }
    }

    .frame-panel {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(8.5625rem, 1fr));
      margin: auto;
      place-content: center;
    }

    .details-lg {
      display: none;
    }

    .overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      position: fixed;
      background: rgb(0 0 0 / 56%);
      backdrop-filter: blur(1px);
    }

    .details-sm {
      width: 95vw;
      display: block;
      position: absolute;
      top: 0;
      border: 0.0625rem solid var(--cu-white-900);
      border-radius: 0.25rem;
      overflow: hidden;
      max-height: 80vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      z-index: 11;

      .tool-box-sm {
        height: 1.5rem;
        background: var(--cu-grey-700);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.875rem;
        position: sticky;
        top: 0;
        z-index: 10;
      }

      .frame-details-display {
        align-items: center;
        padding: 0 0.9375rem 0.9375rem 0.9375rem;
        background: var(--cu-grey-700);
        border-radius: 0;

        .frame-nft-img {
          width: 81vw;
        }
      }
    }
  }

  .data-title {
    color: var(--cu-black-30);
  }

  .data-value {
    color: var(--cu-white-900);
  }

  .zoom-frame {
    height: 1.75rem;
    width: 1.75rem;
  }

  .close-frame {
    height: 1.75rem;
    width: 1.75rem;
  }
}

.zoom-imgs {
  max-height: 100vh;
  .zoom-image-container {
    img {
      height: 99vh;
    }
  }
  @media screen and (max-width: 550px) {
    height: unset !important;
  }
}

.frame-detail-panel {
  position: relative;

  .framshop-details-section {

    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 0rem 0 1rem;
    .frmae-tab-button {
      font-size: 1rem;
      color: var(--cu-black-10);

      &.selectedFrame {
        color: var(--cu-neutral-100);
      }
    }
    .title {
      color: #64748b;
      font-size: 0.875rem;
      width: 5rem;
      text-align: right;
    }
    .value {
      font-size: 0.875rem;
    }
  }

  .framshop-top-section {
    max-height: calc(100vh - 8rem);
    width: 100%;
  }
}

.frame-detail-popup-panel {
  justify-content: center;
}

.frame-nft-imgs {
  span {
    z-index: 5;
  }
}

.frame-nft-imgs-skelton {
  span {
    --base-color: var(--cu-black-900) !important;
    --highlight-color: var(--cu-black-900) !important;
    z-index: 2;
  }
}
.frameshop-right-panel {
  // position: absolute;
  // bottom: 1rem;
  left: 0;
  right: 0;
  @media screen and (max-width: 550px) {
    position: fixed;
    padding: 0 1.43rem;
    bottom: 2rem;
    overflow-x: hidden;
  }
}
.bottom-panel-container {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.75rem;
  border: 1px solid var(--cu-black-70);
  background: var(--neutral-900);
  width: 100%;
  padding: 8px 16px;
}
.price-container {
  display: flex;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.purchase {
  color: var(--neutral-50, #f7f8f9);
  font-family: ZenKaku;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
  text-transform: capitalize;
}
.availability-box {
  display: flex;
  justify-content: space-between;
}
.perpetual {
  display: flex;
  padding: 0.4rem;
  border-radius: 10px;
  padding-left: 1rem;
  justify-content: start;
  align-items: center;
  border: 1px solid var(--cu-black-650);
  // position: absolute;
  // bottom: 11rem;
  left: 0;
  right: 0;
}
.details-perpetual {
  display: flex;
  flex-direction: column;
}
.close-icon{
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url('/Assets/Icons/close-icon.svg');
  &:hover{
    background-image: url('/Assets/Images/close-circle-icon.svg');
  }
}
