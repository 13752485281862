.quill > .ql-container > .ql-editor.ql-blank::before {
    color: var(--cu-white-450);
    font-size: 0.625rem;
    opacity: 0.9;
}

.quill > .ql-container {
    border: 1px solid var(--cu-black-100);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .ql-toolbar.ql-snow {
        border: 1px solid var(--cu-black-100);
    }
    .ql-editor.ql-blank::before {
        font-style: normal;
        color: var(--cu-black-10);
    }
}
.quill {
    * {
        font-family: inherit;
    }
    .ql-toolbar.ql-snow {
        border: 1px solid var(--cu-black-100);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .ql-tooltip {
        left: 0 !important;
    }
}
