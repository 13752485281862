.footer {
    background: var(--cu-black-500);
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0rem 2rem 2rem 2rem;
    flex-direction: column;
    transition: all 0.75s;
    transition-delay: 0.75s;
    gap: 1rem;
 
   
    .head-text {
        transition-delay: 1s;
    }
    &.active {
        gap: 1.5rem;
        align-items: center;
        padding: 1rem;
        transition-delay: 0s;
        
    }
    .social-icons {
    height: .75rem !important;
    width: .75rem !important;
    }
    
}