.details-section {
    padding: 0.42857rem 0rem;
    justify-content: start;
    @media screen and (max-width: 550px){ 
        justify-content: start;
    }
    .details-item {
        display: flex;
        justify-content: center;
        gap: 0.3rem;
        font-size: 0.85rem;
        padding: 0 0.5rem;
        @media screen and (max-width: 550px){ 
            font-size: 0.675rem;
        } 
        .item-name {
            color: var(--cu-grey-650);
        }
    }
    .border-right {
        border-right-width: 1px;
        border-right-color: var(--cu-black-100)
    }
}