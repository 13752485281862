.status {
    position: absolute;
    font-size: .6rem;
    bottom: 0;
    color: var(--cu-white-700);
    z-index: 100;
    background: var(--cu-black-500);

    @media screen and (max-width: 550px) {
        position: fixed;
    }
}