.wallet-select-pop {
    &.wallet-loading {
        .popup-template { 
            left: 31%;
        }
    }
    .popup-template {
        top: 0;
        bottom: 0;
        left: 35%;     
        min-width: 45rem;   
        @media screen and (max-width: 550px) { 
            left: 1rem;
            min-width: unset;
            right: 1rem;
        }
        .popup-template-body {
            padding: 2.5rem;
            @media screen and (max-width: 550px) {  
                max-height: 100vh;
            }
            .head, .foot  {
                @media screen and (max-width: 550px) {  
                    font-size: 0.75rem;
                }
            }
            .body {
                @media screen and (max-width: 550px) {  
                    flex-wrap: wrap;
                    justify-content: center;
                }
            } 
        }
    }
    .popup-overlay {
        z-index: 11;
    }
    &.close-popup {
        display: none;
    }
    .body {
        .wallet-icon {
            img {
                margin: auto;
                min-height: 3.8rem;
                max-height: 3.8rem;
                width: 62px;
                @media screen and (max-width: 550px) {  
                    min-height: 25px;
                    width: 25px;
                }
            }
        }
        .wallet-label {
            font-size: 0.75rem;
            font-weight: 600;
            @media screen and (max-width: 550px) {  
                font-size: 0.625rem;
            }
        }
        .wallet-button {
            display: flex;
            height: 27px;
            max-width: 72px;
            justify-content: center;
            border-radius: 10px;
            border: 0.5px solid var(--dark-mode-700, #24272F);
            background: var(--neutral-900, #0D0F11);
            color: var(--cu-black-5);
            font-weight: 400;
            font-size: 0.75rem;
            padding: 0.625rem;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            @media screen and (max-width: 550px) {  
                font-size: 0.625rem;
                margin-top: 0rem;
            }
        }
    }
    .error-msg {
        padding: 1rem;
        animation: fade 1s linear;
        max-width: 25rem;
        background: var(--cu-red-400);
        border: 1px solid var(--cu-red-550);
        border-radius: 0.625rem;
    }
    @keyframes fade {
        0% { opacity: 0 }
        100% { opacity: 1 }
      }
}