.common__data-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 1rem;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 0.5px solid var(--cu-black-100);
    &:last-child{
        border-right: none;  
    }
    gap: .2rem;
    .data-box-id{
        border-right: none;
    }

    .data-title {
        text-align: left;
        font-weight: 400;
        font-size: 0.75rem;
        color: var(--cu-black-10);
    }

    .data-value {
        text-align: left;
        font-weight: 400;
        color: var(--cu-white-700);
        font-size: 0.75rem;
        align-items: center;
        text-decoration: none;
    }
    .common_image_style {
        width: 1.1rem;
        height: 1.1rem;
        margin: 0 0.1rem 0 0;
    }
    .shapes_box-style{
        width: 1.5rem;
        height: 1rem;
        // left: -0.1rem;
        position: relative;
    }
}