.image-tab-item {
    iframe {
        pointer-events: none;
    }
    &.file-type-box{
        width: 100%;
        aspect-ratio: 1;
        position: relative;
        
            .data-type{
                opacity: 1;
                &[tooltip]:hover::after {
                    content: attr(tooltip);
                    position: fixed;
                    padding: 0.3125rem 0.75rem;
                    transform: translate(35%, -194%);
                    font-size: 0.75rem;
                    border-radius: 0.75rem;
                    border:1px solid var(--dark-mode-300); ;
                    background: var(--Neutral-900, #0D0F11);
                    width: max-content;
                    margin-bottom: 5px;
                    display: inline-block;
                  }
                .media-file-type{
                    scale: 1;
                }
            }
        
    }
}
.pop-up-filetype-wrapper{
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
}