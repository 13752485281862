.image-grid {
    height: 100%;
    overflow-y: auto;
    .img-wrapper{
    aspect-ratio: 1;
    img{
        width: 100%;
        height: 100%;
    }
    }
    .pieces-loader{
        .react-loading-skeleton{
            aspect-ratio: 1;
        }
    }
}

