.purchase-section {
    :is(&.sm-none,.sm-none) {
        @media screen and (max-width: 550px) { 
            display: none;
        }
    }
    .purchase-select-filter {
        .select-box--container {
            min-width: 7rem;
        }
    }
    .pruchase-filter-items {
        left: unset;        
        min-width: -webkit-fill-available;
    }
    .grey-w-color{
        color: var(--cu-white-550) !important;
    }
    table {
        th, td {
            width: 15%;
            span {
                word-break: break-all;
            }
        }
    }
    .purchase-table-head {
        tr {
            border-bottom: 1px solid var(--cu-black-100);
        }
        th {
            padding: 0 1rem 1rem;
            color: var(--cu-black-10);
            font-weight: 600;
        }
    }
    .purchase-table-body {
        td {
            padding: 1rem;
        }
    }
}

.sm-block {
    display: none;
    @media screen and (max-width: 550px) { 
        display: block;
        margin-top: 1rem;
        &.purchase-wrap{
            // margin-top: 1rem;
        }
        .purchase-container-sm {
            .purchase-select-filter {
                .select-box--container {
                    min-width: 7rem;
                }
            }
            .pruchase-filter-items {
                left: unset;
                min-width: 7rem;
            }
            .purchase-item {
                padding: 1rem 0;
                border-bottom: 1px solid var(--cu-black-100);
                font-size: 0.625rem;
                &:first-child{
                    padding: 0rem 0rem 1rem 0rem;   
                }
                &:last-child{
                    border-bottom: none;
                }
                .sm-date {
                    color: var(--cu-black-10);
                }
                .highlight-color{
                    color: var(--cu-blue-70);
                    font-weight: 600;
                }
                .font-normal{
                    font-weight: 400;
                }
                .item-desc {
                    font-size: 0.75rem;
                }
                .item-name { 
                    color: var(--cu-blue-lite);
                    font-weight: 600;
                }
                .item-right {
                    .sm-date {
                        color: var(--cu-black-10);
                    }
                }
            }
        }
    }
}