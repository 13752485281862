.top-menu-bar {
    height: 2.75rem;
    border: 1px solid var(--dark-mode-300);
    border-radius: 6px;
    padding: 0 1.25rem;
    margin: 0rem 1rem;
    @media screen and (max-width:550px){
        height: 3rem;
        padding: 0.35rem 1.15rem;  
        margin: 0px;
        width: 100%;
        background: var(--cu-black-900);
        .skelton-banner
        {.react-loading-skeleton{
            height: 3.2rem !important;
        }
    }
        &.scroll-header{
            padding: 0.35rem 0rem 0.35rem 1rem;  
        }
    }
    .img-icon{
        @media screen and (max-width:550px){ 
            width: 1.4rem;
            height: 1.4rem;
        }  
    }
    .back  {
        @media screen and (max-width:550px){ 
            flex: 0 0 auto;
        }
    }
    .menu-icons {
        @media screen and (max-width:550px){
            gap: 2rem;
            width: 100%;
            justify-content: start;
        }
        .caption-controls {
            @media screen and (max-width:550px){
                gap: 1.5rem;
                justify-content: space-between;
                width: 100%;
            }
        }
        .dropdown-types{
            @media screen and (max-width:550px){
                gap: 1.5rem;
               // width: 30%;
                justify-content: space-between;
            } 
            .dropdown-select-box-open {
                .dropdown-select-box-items {
                    @media screen and (max-width:550px){ 
                        position: fixed;
                        left: 0;
                        top: auto;
                        right: 0;
                        bottom: 2.875rem;
                        // top: calc(100% - 23%);
                    }
                }
            }
            
        }
        .display-types{
            @media screen and (max-width:550px){
                gap: 1.5rem;
                justify-content: space-between;
            } 
        }
        .sorting-types{
            @media screen and (max-width:550px){
                gap: 1.5rem;
                //width: 100%;
                min-width: 1.5rem;
                justify-content: space-between;
                .select-box-items {
                    left: -60px;
                }
            } 
            .select-box-open {
                .select-box-items {
                    @media screen and (max-width:550px){ 
                        position: fixed;
                        left: 0;
                        top: auto;
                        bottom: 2.875rem;
                        // top: calc(100% - 30%);
                    }
                }
            }
        }
        .active-icons {
            img {
                filter: none;
                -webkit-filter: none;
                &.activeIcon {
                    filter: grayscale(0.7) brightness(1.9);
                    -webkit-filter: grayscale(0.7) brightness(1.9);
                }
            }
            .non-filter-icon {
                img {
                    filter: none;
                    -webkit-filter: none
                }
            }
        }
        .refresh-section {
            img.active {
                -webkit-animation:spin 4s linear infinite;
                -moz-animation:spin 4s linear infinite;
                animation:spin 4s linear infinite;
                @-moz-keyframes spin { 
                    100% { -moz-transform: rotate(360deg); } 
                }
                @-webkit-keyframes spin { 
                    100% { -webkit-transform: rotate(360deg); } 
                }
                @keyframes spin { 
                    100% { 
                        -webkit-transform: rotate(360deg); 
                        transform:rotate(360deg); 
                    } 
                }
            }

        }
    }
    .top-sort-select {
        height: 100%;
    }
    .top-menu-search {
        .search {
            input {
                background-color: unset !important;
                padding-left:  0.0625rem !important;
                @media screen and (max-width:550px){
                    padding-left:  1rem !important;
                }
            }
        }
    }
    &.scroll-header-back {
        .menu-list {
            .menu-container {  
                @media screen and (max-width:550px){
                    width: clamp(min(10vw, 20rem), 80vw, max(100vw, 55rem))
                } 
            }
        }
    }
    .menu-list {
        overflow: auto;
-webkit-overflow-scrolling: touch;
        .menu-container {
            color: var(--cu-black-10);
            font-size: 12px;
            align-items: center;
            overflow: auto;
-webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none; 
            &::-webkit-scrollbar { 
                display: none;
            }
            
            @media screen and (max-width:550px){
                padding-right: 1rem;
                width: clamp(min(10vw, 20rem), 90vw, max(100vw, 55rem))
            }

            .active {
                color: var(--neutral-100, #E7EAEE);
                @media screen and (max-width:550px){
                    text-decoration:none;
                }

            }
        }
        .support-button {
            width: auto;
            margin-right: 0.5rem;
            a {
                border: 1px solid var(--cu-black-30);
                color: var(--cu-black-10);
                border-radius: 0.25rem;
                padding: 0 0.25rem;
                font-size: 0.875rem;
            }
        }
    }
    .disabled {
        cursor: not-allowed;
    }
    .scroll-menu{
        display: flex;
        overflow: auto;
-webkit-overflow-scrolling: touch;
        gap: 0.625rem;
        padding-right: 1rem;
        scrollbar-width: none;
        -ms-overflow-style: none;  
        .tab-data {
            color: var(--cu-black-10);
            font-size: 14px;
            font-weight: 600;
            align-items: center;
            &.active {
                color: var(--cu-blue-70);
            }
        }
    &::-webkit-scrollbar { 
        width: 0;
        height: 0;
    }
    }
}

.top-menu-bar-loading {
    border-color: transparent;
}