.listen-playlist{
    .no-data{
        min-height: 12rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .listen-list-title{
        padding-bottom: 1.25rem;
        border-bottom: 1px solid var(--cu-black-400);
        padding-right: 0.625rem;
        margin-right: 1rem;
        padding-left: 0.75rem;
        margin-bottom: 1.5rem;
        .header-title{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            color: var(--cu-black-5);
        }
    }
    .filter-section{
       .playlist-icon{
        width: 2rem;
        height: 2rem;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
        background-image: url('/Assets/Icons/playlist.svg');
        &:hover{
          background-image: url('/Assets/Icons/playlist-hover.svg');
        }
       } 
       .search-section{
        .input-field {
            img{
            top: 0.5rem;
            left: 0.5rem;
        }
    }
    input{
        &.form-input{
            padding:0.5rem !important;
            padding-left: 1.75rem !important;
        }
    }
       }
    }
    .music-player {
        &.collectionlevel{
            padding-right: 1rem;
        }
       .list-player{
        background: transparent;
        .track{
            margin-right: 0rem;
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
            margin: 0.625rem 0rem;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: -0.625rem;
                background: var(--cu-black-400);
            }
        }
        .list-body{
            background: transparent !important;
            margin-left: 0rem;
            margin-right: 0rem;
            .track-number{
                .list-number{
                    font-size: 1rem;
                    font-weight: 400;
                    color: var(--cu-white-700);
                }
            }
          .main-info-cont{
            .title-cont{
                .title{
                    font-size: 1rem;
                    font-weight: 400;
                    color: var(--cu-white-900);
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                }
            }
          }  
          .duration-cont{
           // margin-right: 0rem;
            .number{
                font-size: 1rem;
                font-weight: 400;
                color: var(--cu-white-700);
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            }
          }
        }
       } 
    }
    &.popup-listen{
        .filter-section{
            padding-right: 0.625rem;
        }
        .listen-list-title{
            margin-right: 0rem;
        }
        .music-player {
            padding-right: 0rem;
        }
    }
}