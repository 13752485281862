.nav-bar {
    flex: 0 0 15.5rem;
    overflow-y: auto;
    scroll-behavior: smooth;        
    transition: all .75s;
    transition-delay: .5s;
    &::-webkit-scrollbar-track {
        background: var(--cu-black-900);
        width: 2.5rem;
    }
    &.active {
        flex-basis: 5.5rem;
        transition-delay: 0s;
    }
}
.pt-4-3{
    @media screen and (max-width: 550px) {
    padding-top: 4.3rem !important;
    }
}
.content {
    position: relative;
    flex: 1 1 calc(100% - 15.4375rem);
    max-width: calc(100% - 15.5rem);      
    transition: flex,max-width .75s;
    transition-delay: .5s;
    &.active {
        max-width: calc(100% - 5.5rem);
        flex-basis: calc(100% - 5.5rem);
        transition-delay: 0s;
    }
    @media screen and (max-width: 550px) {
        // padding-top: 5.5rem;
        padding-top: 3.125rem;
        &.filter-less{
            // padding-top: 2.9375rem;
        }
    }

    .footer-alpha-warning {
        position: absolute;
        bottom:1em;
        color: var(--cu-black-10);
        background: var(--cu-black-500);
        padding: 1em 1.5em;
        border-radius: 0.25em;
        left:0;
        right:0;
        margin:auto;
        width: fit-content;
        display: flex;
        z-index: 100;

        .info-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin: auto 1em auto 0;
        }

        .close-icon {
            height: fit-content;
            margin: 0.2em 0 auto 1em;
        }
    }
}
.min-content-height{
    @media screen and (max-width: 550px) {
        min-height: 90vh;
    }  
}
.content-area-height {
    background-color: var(--cu-black-700);
    height: 100vh;
    @media screen and (min-width: 551px) {
        height: calc(100% - 4.75rem);
    }
    @media screen and (max-width: 550px) { 
      //  height: var(--main-page-height);
      height: auto;
    }


}

.mobile-nav {
    position: absolute;
    z-index: 9;
    width: 100%;
    display: none;
}

.menu-bar {
    display: none;
    z-index: 12;
}

@media screen and (max-width: 1030px) {
    .nav-bar {
        flex: 0 0 11.875rem;
    }

    .content {
        max-width: calc(100% - 11.875rem);
    }
}

@media screen and (max-width: 550px) {
    .nav-overlay {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        position: fixed;
        display: none;

        &.nav-active {
            display: block;
        }

    }
    .nav-in{
    //  height: 40.3rem;
    }
    .nav-out{
        height: 35.12rem;
    }
    .nav-bar {
        top: 3.1rem;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        position: fixed;
        background-color: transparent;
    }

    .footer {
        display: none;
    }

    .content {
        max-width: 100%;
        height: 100%;
    }


    .menu-bar {
        display: flex;
    }

    .desktop-nav {
        display: none;
    }

    .mobile-nav {
        display: block;
        width: 0%;
        transition: width .25s ease-in-out 0s;
        // height: calc(100% - 3.1rem);
        //height: fit-content;
        max-height:  calc(100vh - 3rem);
        overflow: auto;
    }

    .mobile-nav.nav-active {
        width: 100%;
        // min-width: max-content;
        transition: width .25s ease-in-out 0s;
    }


}
    .draggable-music-icon{
        position:fixed;
        z-index:1001;
        bottom: 2.5rem;
        right: 1.25rem;
    }