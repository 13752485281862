.my-gallery {
    &-card {
        border: 1px solid var(--cu-black-100);
        background: var(--cu-black-500);
        min-height: 15rem;
        border-radius: 0.25rem;
        position: relative;
        width: 100%;

        .my-gallery-card-image-container:hover {
            .content-overlay {
                opacity: 1;
            }

            .content-details {
                top: 50%;
                left: 50%;
                opacity: 1;
            }
        }

        .content-overlay {
            background: var(--cu-black-trans-600);
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            -webkit-transition: all 0.4s ease-in-out 0s;
            -moz-transition: all 0.4s ease-in-out 0s;
            transition: all 0.4s ease-in-out 0s;
            z-index: 1;
        }

        .content-details {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            position: absolute;
            text-align: center;
            padding-left: 1em;
            padding-right: 1em;
            width: 100%;
            top: 50%;
            left: 50%;
            opacity: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            -webkit-transition: all 0.3s ease-in-out 0s;
            -moz-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
            z-index: 2;
        }

        &-image {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            overflow: hidden;
            height: 100%;
            width: 100%;
            background-position: top center;
            border-radius: 0.25rem;
        }

        &-name {
            max-width: 11rem;
            word-break: break-all;
            text-align: start;
        }
    }

    &-banner {
        .pr-sm{
            padding-right: 1rem;
            @media screen and (max-width: 551px) {
                padding-right: 1rem;
            }
        }
        @media screen and (min-width: 551px) {
            max-height: calc(100vh - 6rem);
            overflow: auto;
            -webkit-overflow-scrolling: touch;

        }

        @media screen and (max-width: 550px) {
            // min-height: calc(100vh - 5rem);
            padding-top: 0.2rem;
        }
    }

    &-card .full-image {
        max-height: 13rem;
        min-height: 13rem;
    }

    &-card.background-image {
        padding: 0rem 0rem 0.68rem 0rem;
    }
}



.screenshot-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        min-height: 9rem;
        margin: 0 auto;
        object-fit: contain;
    }
}

@media screen and (max-width: 550px) {
    .nft-gallery-add {
        border: 1px solid var(--cu-blue-60);
        border-radius: 4px;
    }
}
.warning-toolkit{
    position: absolute;
    top: 2rem;
    right: 1rem;
    width: auto;
    opacity: 0;
    visibility: hidden;
    &.active{
        opacity: 1;
        z-index: 9;
        visibility: visible;
    }
}