.info-list-wrp {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 550px) {
        min-height: calc(100vh - 5rem);
    }

    .overflow-flex-auto {
        flex: auto;
        overflow: hidden;

        .grid-style-row {
            grid-template-rows: 1fr auto;
        }
    }

    .selected-container {
        overflow: auto;
-webkit-overflow-scrolling: touch;

        @media screen and (max-width: 550px) {
            padding-top: 0.5rem;
        }

        &-nav {
            position: absolute;
            z-index: 20;
            padding: 0 2rem;
            bottom: 0;
            left: 1rem;
            right: 1rem;
            background: var(--cu-white-trans-600);
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            transition: transform .5s ease;
            box-shadow: 0px 2px 5px 1px var(--cu-black-trans-500);
            padding-top: 0.5rem;

            @media screen and (max-width: 550px) {
                position: fixed;
            }

            &-btn {
                background: url(~/src/Assets/Icons/arrow_down_blue.svg);
                background-repeat: no-repeat;
                width: 1rem;
                height: 1rem;
                background-size: 100%;
                transition: transform .5s ease;
                transform: rotateZ(180deg);
            }

            &-open {
                transform: translateY(0);
            }

            &-closed {
                transform: translateY(calc(100% - 1.8rem));

                .selected-container-nav-btn {
                    transform: rotateZ(0deg);
                }
            }
        }
    }
}

.slide-arrows {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    top: 50%;

    @media screen and (max-width: 550px) {
        top: 15%;
    }
}

.slide-arrow-right {
    float: right;
    right: 1rem;
}

.slide-arrow-left {
    float: left;
    left: 1rem;
}