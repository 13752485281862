.accordion-wrapper {
  .expander {
    .accordion-arrow {
      &:hover{
        background: var(--cu-black-400);
      }
      display: flex;
      background: var(--neutral-900);
      // align-items: center;
      padding: 0.625rem 0.5rem;
      gap: .5rem;
      border-radius: 0.375rem;
      // border: 1px solid var(--cu-black-400);
      padding-right: 1rem;
      justify-content: space-between;
      @media screen and (max-width: 550px) {
        flex-direction: column;
        gap: 1rem;
      }
      // &::after {
      //   content: "";
      //   background: url("/Assets/Icons/edit.svg");
      //   width: 1.25rem;
      //   height: 1.25rem;
      //   background-repeat: no-repeat;
      //   top: auto;
      //   background-size: 100% 100%;
      //   margin-right: .5rem;
      // }
      .accordion-title-section{
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &.arrow{
        &::after{
        background: url('/Assets/Icons/arrow_down_blue.svg');
        width: 1rem;
        height: 1rem;
        transform-origin: center;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.expanded {
        color: var(--cu-white-200);
        border-bottom: none;
        &::after {
            transform: rotateZ(180deg);
        }
    }

    &.collapsed {
        &::after {
            transform: rotateZ(0deg);
        }
    }
    }

      &.expanded {
        color: var(--cu-white);
        border-radius: 0.312rem 0.312rem 0rem 0rem ;
        &::after {
          transform: rotateZ(0deg);
        }
      }
      &.accordion-arrow-expanded {
        border-radius: 0.312rem 0.312rem 0rem 0rem;
        margin-bottom: 0rem;
      }
      .time-title-wrapper {
        width: 14.25rem;
        align-items: center;
        gap: 0.625rem;
        @media screen and (max-width: 550px) {
          margin-right: auto;
          padding-bottom: 0.375rem;
          border-bottom: 1px solid var(--cu-black-100);
          width: 100%;
        }
      }

      .date {
        font-size: 0.75rem;
        color: var(--neutral-500) ;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 0.0625rem;
        @media screen and (max-width: 550px) {
          white-space: initial;
          overflow: auto;
-webkit-overflow-scrolling: touch;
          text-overflow: initial;
          margin-top: 0.125rem;
        }
      }
      .titles-text {
        font-size: 0.875rem;
        color: var(--cu-white-200);
        font-weight: 500;
        line-height: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 12rem;
        @media screen and (max-width: 550px) {
          white-space: initial;
          overflow: auto;
-webkit-overflow-scrolling: touch;
          text-overflow: initial;
        }
        &.events {
          overflow: initial;
          @media screen and (max-width: 550px) {
            margin-right: auto;
            margin-top: 1.125rem;
            margin-bottom: 1.125rem;
          }
        }
      }
      .brief-desc {
        font-size: 0.875rem;
        color: var(--cu-black-5);
        font-weight: 300;
        line-height: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media screen and (max-width: 550px) {
          margin-right: auto;
          white-space: initial;
          overflow: auto;
-webkit-overflow-scrolling: touch;
          text-overflow: initial;
        }
      }
    }
    .expander-content {
      font-size: 0.875rem;
      color: var(--cu-white-700);
      // background: var(--neutral-900);
      // border-left: 1px solid var(--cu-black-400);
      // border-bottom: 1px solid var(--cu-black-400);
      // border-right: 1px solid var(--cu-black-400);
      // border-radius: 0.312rem;
      white-space: pre-wrap;

      // &:not(.expanded) {
      //   border-bottom: 1px solid var(--cu-black-400);
      //   border-radius: 0.312rem;
      // }
    }

  }
  &.accordion-with-edit{
    .accordion-title-section{
      .icons-overlay-edit-delete{
        visibility: hidden;
        opacity: 0;
      }
      &:hover{
        .icons-overlay-edit-delete{
          visibility: visible;
          opacity: 1;
                  }  
      }
    }
  }
}

.list-event{
  .accordion-wrapper {
    .expander {
      .accordion-arrow {
        gap:0.125rem;
        .brief-desc{
          margin-left: 1.75rem;
          @media screen and (max-width: 550px) {
            margin-left: 0px;
          }
        }
      }
    }
  }
}