.info-list-wrp {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 2rem;

  @media screen and (max-width: 550px) {
    min-height: calc(100vh - 5rem);
  }

  .info-list-wrp2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 10rem;

    @media screen and (max-width: 550px) {
      min-height: calc(100vh - 5rem);
    }
  }

  .info-desk-mob {
    @media screen and (max-width: 550px) {
      padding: 2rem 1rem;
    }
  }

  .overflow-flex-auto {
    flex: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .home-grid {
      grid-template-columns: repeat(auto-fill, minmax(17.375em, 1fr));
    }
  }
}

.info-desk-home-grid {
  margin-top: 0rem !important;
  @media screen and (max-width: 550px) {
    padding: 0px !important;
  }
}

.terms {
  width: 100%;
  height: 100%;
  background-color: var(--cu-black-500);
  border: 1px solid var(--cu-black-100);
  border-radius: 0.25rem;
}

.terms-head {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.1875rem;
}
.contact-container {
  .info-container {
    display: flex;
    padding: 1.142rem;
    gap: 0.71429rem;
    border-radius: 0.29rem;
    border: 1px solid var(--cu-black-400);
    flex-direction: column;
    .info-name {
      font-weight: 600;
    }
    .info-social-btn {
      color: var(--cu-black-10);
      padding: 0.28571rem 0.57143rem;
      border-radius: 0.28571rem;
      border: 1px solid var(--cu-black-10);
      cursor: pointer;
    }
  }
}