@import '../Styles/main.scss';

.App {
  text-align: center;
  gap: 10px;
  color: var(--cu-white-900);
  overflow: hidden;
  .border-rad-10 {
    border-radius: 10px;
  }
.grey-border{
  border: 1px solid var(--cu-black-100);
}
  .border-y-0 {
    border-bottom: 0;
    border-top: 0;
  }

  .font-10 {
    font-size: .625rem;
  }

  .font-12 {
    font-size: .75rem;
    line-height: 0.875rem;
  }

  h4 {
    font-size: 14px
  }

  .font-14 {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  .error-validation {
    font-size: .625rem;
    color: var(--cu-red-550);
    line-height: 1rem;
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 2.5rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--cu-grey-650);
    border: 3px solid transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .loader-bg {
    z-index: 100;
    width: 100%;
    background: rgb(0, 0, 0, 67%);
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    span {
      width: 5rem;
      height: 2rem;
    }
  }

  .auth-avatar {
    border-radius: 50%;
    border: 0.125rem solid rgb(255 255 255 / 20%);
    padding: 0.88rem;
    width: 4.375rem;
  }
}

.dangerously-innerHTML-container {
  h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, small, strong, sub, sup, li, figure, figcaption, hr {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
  }

  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: #fff;
  }
  
  /* Heading1 */
  h1 {
    font-size: 2.25em;
  }
  /* Heading2 */
  h2 {
    font-size: 1.5em;
  }
  /* Heading3*/
  h3 {
    font-size: 1.25em;
  }
  /* Heading4 */
  h4 {
    font-size: 1em;
  }
  /* Heading5 */
  h5 {
    font-size: 0.75em;
  }
  /* Heading6 */
  h6 {
    font-size: 0.5em;
  }
  
  /* Paragraphs */
  p {
    margin: 1em 0;
  }
  
  /* Lists */
  ul, ol {
    margin: 0;
    padding: 0 0 0 2em; 
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    li {
      margin:  0;
      padding: 0;
      display: list-item;
      text-align: -webkit-match-parent;
    }
  }
  ul li {
    list-style: disc;
  }
  ol li {
    list-style: decimal;
  }


  /* Blockquote */
  blockquote {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-left: 2px solid #ccc;
  }
  
  /* Preformatted text */
  pre {
    margin: 1em 0;
    padding: 0.5em;
    background-color: #f8f8f8;
    overflow: auto;
    font-family: monospace;
  }
  
  /* Horizontal rule */
  hr {
    border: 1px solid #ccc;
    margin: 1em 0;
  }
  a {
    color: #007bff; 
    text-decoration: underline;
    cursor: pointer; 
    transition: all 0.3s;
    opacity: 0.9;
    &:hover {
      opacity: 1;
      text-decoration: none; 
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.utilIcons {
  width: .7rem;
  cursor: pointer;
}

.icons {
  width: 1rem;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.posts-skeleton-wrap{
  margin-top: 1rem;
  .details-skeleton{
    grid-template-columns: auto;
    gap: 0.5rem;
    .skeleton-wrapper {
      height: 5rem;
      aspect-ratio: initial;
  }
  }
}
.event-skeleton-wrap{
  .details-skeleton{
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

.btn-style{
  border: none;
  border-radius: 50%;
  border: 1px solid var(--cu-black-100);
  color: var(--cu-white-900);
  font-size: .75rem;
  padding: 0.65rem 1rem;
  transition: all .3s;
&.save{
    background: var(--cu-black-500);
}
&:hover {
  background: var(--dark-mode-300);
}
&.active{
  border: 1px solid var(--cu-white-900);
}
}

.new-btn_style_001, .new-btn_style_002 {
  transition: all 0.3s;
  color: var(--cu-white-900);
  border: none;
  box-shadow: 0 0 0 1px var(--cu-black-100);
  &:hover {
    background: var(--dark-mode-300);
  }
}
.new-btn_style_002 {
  background: var(--dark-mode-900);
}
