.preview-card-image {
    .preview-card-sq {
      .nft-sq-card {
        height: var(--collection-gallery-previewcard-height) !important;
        .gallery-images-position,
        .gallery-images {
          height: 100%;
          display: flex;
        }
      }
      .nft-selected-name {
        font-size: 0.875rem;
        line-height: normal;
        height: auto;
      }
    }
    .nfts-selected {
      max-height: 100%;
    }
  }
  .preview {
    border-radius: 0.28rem;
  
    &-user {
      a {
        font-size: 0.71rem;
        color: var(--cu-white-100);
      }
    }
  
    &-gallery {
      padding: 0rem !important;
      position: relative;
      width: 100%;
  
      @media screen and (max-width: 320px) {
        width: 18rem;
      }
  
      &-full {
        margin-bottom: 2rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 10rem);
        height: 100%;
  
        @media screen and (max-width: 550px) {
          max-height: calc(100vh - 11.5rem);
        }
      }
    }
  }
  .gallery-preview-wall {
    .gallery-images-position {
      height: 100%;
    }
  }
  .user-details {
    font-size: 0.85rem;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  
    &-value {
      color: var(--cu-white-700);
  
      &-head {
        color: var(--cu-white-900);
      }
    }
  
    &-label {
      font-size: 0.71rem;
    }
  
    li {
      font-size: 0.71rem;
      color: var(--cu-white-100);
    }
  
    ul li::before {
      content: "\2022";
      color: var(--cu-blue-300);
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  
    &-view {
      li {
        font-size: 0.85rem;
  
        img {
          height: 100%;
        }
      }
  
      span {
        color: var(--cu-grey-600);
      }
  
      li {
        color: var(--cu-white-700);
      }
  
      img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
  
  .user {
    flex: 1 1 31rem;
  }
  
  .headerArea-wrap {
    &-dp {
      height: 5rem;
      overflow: hidden;
      width: 6rem;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  
  .hashtags {
    span {
      color: var(--cu-white-700);
    }
  }
  
  .preview-dimension {
    .content-overlay {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%);
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      z-index: 1;
      height: 100%;
    }
  
    .content-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s ease-in-out 0s;
      -moz-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      z-index: 2;
      border-radius: .25rem;
    }
  }
  
  .preview-new-slider .preview-dimension:hover {
    .content-details {
      top: 50%;
      left: 50%;
    }
  }
  
  .caption-yes {
    filter: none !important;
    height: 1.1rem !important;
    -webkit-filter: none !important;
  }
  
  .caption-no {
    filter: grayscale(0.7) brightness(1.3);
    height: 1.1rem !important;
    -webkit-filter: grayscale(0.7) brightness(1.3);
  }
  
  .card-half {
    max-width: 28.375rem;
  }
  
  .user-card {
    height: 18.75rem;
    border-radius: 0.25rem;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1020px) {
    .user-card {
      height: 100%;
    }
  
    .preview-gallery {
      height: 100%;
    }
  
    .gallery-preview-card {
      gap: 2rem;
    }
  }


  
  .slider-user {
    display: flex;
    min-height: 14.5rem;
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 1rem 1rem 1rem 1rem;
    gap: 1.6rem;
    background: #14171F;
    .detail-wrapper {
      width: 100%;
    }
    .preview-gallery {
      border-radius: .42857rem;
      background-color: var(--cu-black-900);
    }
    @media screen and (max-width: 550px) {
      flex-basis: 100%;
      height: calc(100vh - 8rem);
    }
  }
  .gallery-overlay {
    position: fixed;
    top:0rem;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 1000;
  }
  .slider-user2 {
    max-height: 95vh;
    height: fit-content;
    border-radius: 0.25rem;
    border: 1px solid var(--dark-mode-300);
    overflow: hidden;
    padding: 1rem;
    position: absolute;
    top:5vh;
    left: 2rem;
    right: 2rem;
    margin-top: -2.5vh;
    border-radius: 20px;
    background: var(--Neutral-900, #0D0F11);
    z-index: 10;
    .detail-wrapper {
      padding: 0rem 1rem 0rem 1rem;
      width: 100%;
    }
    .preview-gallery {
      border-radius: 0;
      background-color: var(--cu-black-900);
    }
    @media screen and (max-width: 550px) {
      flex-basis: 100%;
      height: calc(100vh - 8rem);
    }
  }
  
  .frame-shop {
    @media screen and (max-width: 550px) {
      .preview-nft-view {
        padding: 0px;
        height: 100vh;
      }
    }
  }
  
  .preview-nft-view {
    .description-tab {
      // max-height: 60vh;

    }
  
    .frame-size {
      max-height: 36.48vh;
    }
  
    .nft-frame-background {
      border-radius: 0.25rem;
      background: var(--cu-black-700);
    }
  }
  
  .nfts-selected {
    flex: 1 1 25rem;
    overflow-y: auto;
  
    max-height: calc(100% - 20rem);
    @media screen and (max-width: 550px) {
      max-height: 100%;
    }
  }
  
  .user-preview {
    // border-top: 1px solid var(--cu-black-100);
    max-height: calc(100vh - 31rem);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  
    @media screen and (max-width: 550px) {
      .nfts-selected {
        margin-right: 0px;
        overflow: unset;
        margin-bottom: 1rem;
      }
    }
  }
  
  .preview-tab {
    ul {
      width: fit-content;
      gap: 0.8rem !important;
  
      li {
        flex: none !important;
        padding: 0.25rem 0.625rem !important;
        font-size: 0.75rem;
        background: var(--cu-black-500);
        border: 0.0625rem solid var(--cu-black-30);
        border-radius: 0.375rem;
        color: var(--cu-black-30);
        margin-bottom: 0.5rem;
  
        &.tab-page-active {
          background: var(--cu-black-50);
          border: 0.0625rem solid var(--cu-black-30);
          border-radius: 0.375rem;
          color: var(--cu-white-900);
        }
      }
    }
  }
  
  .preview-new-slider {
    width: 100%;
  }
  
  .zoom-nft {
    position: fixed;
    z-index: 12;
    right: 0;
    left: 0;
    top: 0rem;
    bottom: 0rem;
    width: 100%;
    background-color: var(--cu-black-900);
    height: unset;
  
    .view-frame-images {
      height: 100%;
    }
  
    .preview-nft-view .nft-frame-background {
      background: transparent;
    }
  
    .view-frame-images .gallery-images-position {
      background-color: transparent;
    }
  
    .preview-nft-view .description-tab {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      max-height: calc(100vh - 26rem);
      height: 100%;
    }
  
    .frame-size {
      max-height: 100vh;
      height: 100vh;
  
      @media screen and (max-width: 550px) {
        height: unset !important;
      }
    }
  
    .view-gallery-dis {
      min-height: 100%;
      max-height: 100%;
    }
  
    .view-frame-images .gallery-images-position .gallery-selected-image img {
      max-height: 100vh;
    }
  }
  
  .content-trigger {
    inset: 0;
    z-index: 2;
    opacity: 0;
  }
  
  .content-trigger:hover {
    border-radius: .25rem;
    border: 1px solid var(--Dark-Mode-300, #30333A);
    opacity: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.90) 100%);
  }
  
  .user-preview {
    @media screen and (max-width: 550px) {
      max-height: calc(100vh - 27rem);
      padding: 1.5rem 0rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      &.sm-pt-0 {
        padding-top: 0rem;
      }
      .capitalize {
        color: var(--cu-black-5);
      }
  
      .user-details {
        color: var(--cu-white-200);
      }
  
      .grid-lg {
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
      }
  
      .grid {
        grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
      }
    }
  }
  .tab-container-details2 {
    background: var(--neutral-900);
    .tab-data {
      border-bottom: solid 1px var(--cu-black-10);;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--cu-black-10);
      font-size: .875rem;
      line-height: 1.21429rem;

      .tab{
        font-size: 0.875rem;
      line-height: 1.21429rem;
      padding-bottom:  .625rem;
      box-sizing: border-box;
      position: relative;
      &.not-active{
        &::after{
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          background: var(--cu-white-200);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover{
          color: var(--cu-white-200);
          &::after { 
          width: 100%; 
          left: 0; 
        }}
      }
      &.active {
        color: var(--cu-white-200);
        border-bottom: solid 1px var(--cu-white-200);

      }
      }

    }
  }

  .description-details2 {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 0rem;
    align-items: flex-start;
    gap: 1rem 0.57143rem;
    height: 100%;
    .description-data {
      display: flex;
      flex-direction: column;
      font-size: 0.85714rem;
      line-height: 1.21429rem;
      padding: 0rem 0.57143rem 0rem 0rem;
      margin-right: 0.57143rem;
      gap: 0.5rem;
      text-align: left;
      @media screen and (max-width: 550px) {
        font-size: 0.75rem;
      }
      .title {
        width: 7rem;
        text-align: right;
        color: var(--cu-black-10);
        font-size: 0.875rem;
      }
      .data {
        color: var(--cu-white-900);
        max-width: 100%;
        font-size: 0.875rem;
        span{
          word-break: break-word;
        }
        img {
          width: 1.14286rem;
          height: 1.14286rem;
        }
      }
      &:last-child {
        border: unset;
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .nfts-selected-box{
    &.gallery-details{
      .react-loading-skeleton{
        aspect-ratio: 1;
}
    }
  }