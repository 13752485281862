.wallet-connectors {
    width: 100%;
    padding: 1.25rem;

    &-group {
        min-height: 6.5625rem;
    }

    &-list {
        margin-top: 1.875rem;
        padding: 0;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, 6.2rem);
        gap: 2.2rem;
        font-size: 0.75rem;
        text-transform: capitalize;

        button {
            min-width: 5rem;
            border: 1px solid var(--dark-mode-700, #24272F);
            color: var(--neutral-400, #A0ABBB);
            border-radius: 1.25rem;
            line-height: .875rem;
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
            &:hover{
                background: var(--Dark-Mode-300, #30333A);
            }
        }
    }

    .cntOtherWallet {
        border: 0.125rem solid var(--cu-white-900);
        border-radius: 1.25rem;
        font-weight: 600;
        line-height: .875rem;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
    }
}

.wallet-connectors-group {
    img {
        width: 5rem;
        min-height: 80px;
        margin: auto;
        object-fit: contain;
    }
}

.wallet-mob {
    margin-top: 1.875rem;
    padding: 0;
    display: grid;
    align-items: center;
    gap: 1.7rem;
    font-size: 0.75rem;
    text-transform: capitalize;
    width: 100%;
    justify-content: flex-start;

    img {
        height: 3.875rem;
        width: 3.875rem;
    }
}

.add-wallet-sm {

    margin-top: 5rem;
    @media screen and (max-width: 550px) {
        margin-top: 4rem;
    }
}

.wallet-mob-head {
    background-color: transparent;
    top: 6.125rem;
    position: fixed;
    height: 2rem;
    @media screen and (max-width: 550px) {
        top: 5.125rem;
    }
}