.view-gallery-all {
    padding: 0 !important;
    &:hover {
      .tool-box {
        display: flex;
      }
    }
    .tool-box {
      display: none;
    }
    .frame-container-gallery{
      aspect-ratio: 1/1;
    }
    .view-frame-images {
      background-color: var(--cu-black-900);
      border-radius: 0.25rem 0.25rem 0rem 0rem;
      .gallery-images-position {
        background-color: unset;
      }
    }
  }
  
  .tab-container-details {
    // padding: 1.14286rem 2.28571rem 0rem 1.14286rem;
   
    .tab-data {
      border-bottom: solid 1px var(--cu-black-10);;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--cu-black-10);
      font-size: .875rem;
      line-height: 1.21429rem;
  
      .tab{
        font-size: .875rem;
      line-height: 1.21429rem;
      padding-bottom:  .625rem;
      box-sizing: border-box;
      position: relative;
      &.not-active{
        &::after{
          background: none repeat scroll 0 0 transparent;
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 50%;
          position: absolute;
          background: var(--cu-white-200);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
        }
        &:hover{
          color: var(--cu-white-200);
          &::after { 
          width: 100%; 
          left: 0; 
        }}
      }
      &.active {
        color: var(--cu-white-200);
        border-bottom: solid 2px var(--cu-white-200);
        
      }
      }
      
    }
  }
  .description-tab {
    display: flex;
    flex-direction: column;
    width: 100%;
    // border-top: 1px solid var(--cu-black-100);
    font-size: 0.75rem;
    padding-right: .5rem;

    .asset-title {
      color: var(--cu-white-200);
      font-size: 1.125rem;
      font-family: ZenKaku;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 550px) {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .collection-title {
      color: var(--Neutral-100, #E7EAEE);
      font-family: ZenKaku;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: 550px) {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .description-details {
      display: flex;
      padding: 1rem 0 1rem 0rem;
      align-items: flex-start;
      gap: 1rem 0.57143rem;
      flex-wrap: wrap;
      .description-data {
        font-size: 0.85714rem;
        line-height: 1.21429rem;
        gap: 0.28571rem;
        border: 0.5px solid #30333a;
        border-top: none;
        border-left: none;
        border-bottom: none;
        text-align: left;
        @media screen and (max-width: 550px) {
          font-size: 0.75rem;
        }
        .title {
          color: var(--Neutral-300, #B8C0CC);
          font-family: ZenKaku;
          font-size: .875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.28px;
        }
        .data {
          color: var(--Neutral-100, #E7EAEE);
          font-family: ZenKaku;
          font-size: 0.8125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span{
            word-break: break-word;
          }
          img {
            width: 1.14286rem;
            height: 1.14286rem;
          }
        }
        &:last-child {
          border: unset;
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .description-details2 {
      display: flex;
      flex-direction: column;
      padding: 1rem 0 1rem 0rem;
      align-items: flex-start;
      gap: 1rem 0.57143rem;


      .header {
        color: var(--Neutral-300, #B8C0CC);
        font-size: .875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
      }
      .description-data {
        display: flex;
        flex-direction: column;
        font-size: 0.85714rem;
        line-height: 1.21429rem;
        padding: 0rem 0.57143rem 0rem 0rem;
        margin-right: 0.57143rem;
        gap: 0.5rem;
        text-align: left;
        @media screen and (max-width: 550px) {
          font-size: 0.75rem;
        }
        .title {
          width: 7rem;
          text-align: right;
          color: var(--cu-black-10);
          font-size: 0.875rem;
          text-overflow: ellipsis;
          line-height: normal;
          font-style: normal;
          font-weight: 400;
        }
        
        .data {
          color: var(--cu-white-900);
          max-width: 100%;
          font-size: 0.875rem;
          img {
            width: 1.14286rem;
            height: 1.14286rem;
          }
        }
        &:last-child {
          border: unset;
        }
        &:first-child {
          padding-left: 0;
        }
      }
    }
    .data-box {
      display: flex;
      width: 100%;
  
      .data-title {
        text-align: left;
        flex-basis: 7.75rem;
      }
  
      .data-value {
        color: var(--cu-white-700);
        margin-left: 0.625rem;
      }
    }
  }
  
  .view {
    .gallery-zoom {
      height: 1rem;
      width: 1rem;
    }
  
    .gallery-close {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
  
  .view-images {
    .frame-spinner {
      margin-top: -3px;
      height: 103%;
    }
  }
  
  .bp-none {
    padding: 1rem 0rem 0rem 0rem;
    .description-tab {
      border-top: none;
      padding: 0rem;
    }
  }
  
  .close-button {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid var(--dark-mode-300);
    font-size: 0.75rem;
    color: var(--cu-black-10);
  }
  .chevron-container {
    width: 1.5rem;
    position: absolute;
    bottom: -0rem;
  }
  .square-container{
    border: 1px solid #20232A;
    background: var(--cu-black-700);
    border-radius: .25rem;
 width: fit-content;
 &.highlight{
    border: .0625rem solid var(--cu-white-200);
}
  }

  .listing-box{
    .square-container{
      opacity: 1;
      &:hover{
          opacity: 0.5;
      }
    } 
  }